import {
    Avatar,
    Chip,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {mdiClose, mdiInformation} from "@mdi/js";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import TablePagination from "../common/table/TablePagination";
import ExportStadiumGeneraleButton from '../service/ExportStadiumGenerale'
import FilterColumn from "../common/table/FitlerColumn";
import * as React from "react";
import UserProfileCell from "../common/table/UserProfileCell";

function StadiumGeneraleTable({userId, hideUser}) {
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 5,
        last_page: 0,
        total: 0,
        prev: false,
        next: true,
        total_page: 0
    })
    const [detailId, setDetailId] = useState(null)
    const [search, setSearch] = useState('')
    const [searchTimeout, setSearchTimeout] = useState(null)
    const [attendance, setAttendance] = useState(null)

    const _headers = [
        'Kehadiran',
        'Alasan Ketidakhadiran',
    ]

    if (!hideUser) {
        _headers.unshift('Mahasiswa')
    }

    const [headers, setHeaders] = useState(_headers)

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }
        setSearchTimeout(setTimeout(() => {
            fetch()
            setSearchTimeout(null)
        }, 1_000))
    }, [search])

    const fetch = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/admin/registration/sg-attendance', {
                params: {
                    page: pagination.current_page,
                    per_page: pagination.per_page,
                    search: search,
                    sg_attendance: attendance,
                    ...(userId && {user_id: userId})
                }
            })

            if (data) {
                setPagination({
                    current_page: data.current_page,
                    per_page: data.per_page,
                    last_page: data.last_page,
                    prev: Boolean(data.prev),
                    next: Boolean(data.next),
                    total: data.total,
                    total_page: Math.ceil(data.total / data.per_page)
                })
                setItems(data.data)
            }
        } catch (e) {
            toast('Gagal memuat data', {
                type: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetch()
    }, [pagination.per_page, pagination.current_page, attendance])

    return <>
        {/*<AchievementDetail*/}
        {/*    id={detailId}*/}
        {/*    onClose={() => setDetailId(null)}*/}
        {/*    onSuccess={fetch}*/}
        {/*/>*/}

        <Grid container sx={{marginBottom: 2}} spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                    <TextField
                        id="search"
                        size="small"
                        label="Cari"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                    <InputLabel size="small" id="select-status">Status</InputLabel>
                    <Select
                        labelId="select-status"
                        value={attendance}
                        size="small"
                        label="Status"
                        onChange={e => setAttendance(e.target.value)}
                        endAdornment={attendance !== null &&
                            <IconButton onClick={() => setAttendance(null)}>
                                <SvgIcon>
                                    <path d={mdiClose}/>
                                </SvgIcon>
                            </IconButton>}
                        sx={{
                            "& .MuiSelect-iconOutlined": {display: attendance !== null ? 'none' : ''}
                        }}
                    >
                        <MenuItem dense value="attend">Hadir</MenuItem>
                        <MenuItem dense value="absence">Tidak Hadir</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm="auto">
                <FilterColumn columnList={_headers} selectedList={headers} setSelected={setHeaders}/>
            </Grid>

            <Grid item sx={{ display: 'flex', marginLeft: 'auto' }}>
                <div>
                    <ExportStadiumGeneraleButton search={search} attendance={attendance} variant="contained" />
                </div>
            </Grid>
        </Grid>

        <TableContainer component={Paper}>
            {loading && <Box sx={{width: '100%'}}>
                <LinearProgress/>
            </Box>}
            <Table size="medium">
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell key={header}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item.id}>
                            {headers.includes('Mahasiswa') &&
                                <TableCell>
                                    <UserProfileCell
                                        userId={item.id}
                                        userName={item.name}
                                        userImage={item.image}
                                        studentId={item.profile?.student_id}
                                        generation={item.profile?.generation}
                                        studyProgramLevel={item.profile?.study_program?.level}
                                        studyProgramName={item.profile?.study_program?.name}
                                        facultyName={item.profile?.faculty?.name}
                                        isFreeRegistration={item.registration?.is_free_registration}
                                        isCashbackGiven={item.registration?.cashback}
                                    />
                                </TableCell>}
                            {headers.includes('Kehadiran') && <TableCell>
                                {item.registration.sg_attendance !== null && (item.registration.sg_attendance ? 'Hadir' : 'Tidak Hadir')}
                            </TableCell>}
                            {headers.includes('Alasan Ketidakhadiran') && <TableCell>
                                <Typography type="body2">
                                    {item.registration.sg_absence_reason}
                                </Typography>
                            </TableCell>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <Grid container justifyContent="flex-end" direction="row" alignItems="center" style={{marginTop: '12px'}}>
            <Grid item xs="auto">
                <TablePagination pagination={pagination} onChange={setPagination} loading={loading}/>
            </Grid>
        </Grid>
    </>
}

export default StadiumGeneraleTable
