import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {mdiFileExport} from "@mdi/js";
import {Menu, MenuItem, SvgIcon} from "@mui/material";
import axios from "axios";
import {toast} from "react-toastify";
import {generateBasic} from "../../utils/xlsx/xlsx";

function exportXLSX(data) {
    generateBasic({
        title: 'PAB Restek UNY - Prestasi',
        subject: 'PAB Restek UNY',
        filename: 'Prestasi.xlsx',
    }, [
        {
            name: 'Prestasi',
            header: [
                {
                    name: 'name',
                    text: 'Nama',
                    width: 300
                },
                {
                    name: 'student_id',
                    text: 'NIM',
                    width: 150
                },
                {
                    name: 'competition',
                    text: 'Nama Kompetisi',
                    width: 300
                },
                {
                    name: 'organizer',
                    text: 'Penyelenggara',
                    width: 200
                },
                {
                    name: 'achievement',
                    text: 'Pencapaian',
                    width: 200
                },
                {
                    name: 'description',
                    text: 'Deskripsi',
                    width: 300
                },
                {
                    name: 'file',
                    text: 'File',
                    width: 200
                }
            ],
            rows: data.map(v => ({
                name: v.user.name,
                student_id: v.user.profile.student_id,
                competition: v.competition,
                organizer: v.organizer,
                achievement: v.achievement,
                description: v.description,
                file: axios.defaults.baseURL + '/api/download?file=' + v.file,
                status: v.status,
            }))
        }
    ])
}

function ExportAchievementsButton({search, status, ...props}) {
    const [loading, setLoading] = useState(false)

    const download = async (filtered = false) => {
        handleClose()
        setLoading(true)
        try {
            let params
            if (filtered) {
                params = {
                    search: search,
                    status: status,
                }
            }
            const {data} = await axios.get('/api/admin/achievement/all', { params })
            exportXLSX(data)
        } catch (e) {
            toast('Gagal export data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return <>
        <LoadingButton
            {...props}
            loading={loading}
            aria-controls={open ? 'export-achievements-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            startIcon={<SvgIcon>
                <path d={mdiFileExport}/>
            </SvgIcon>}
            onClick={handleClick}
        >
            Export
        </LoadingButton>
        <Menu
            id="export-achievements-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'export-achievements-menu',
            }}
        >
            <MenuItem onClick={() => download()}>Export All</MenuItem>
            <MenuItem onClick={() => download(true)}>Export Filtered</MenuItem>
        </Menu>
    </>
}

export default ExportAchievementsButton