import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
    MenuItem,
    FormControl,
    Select,
    LinearProgress,
    SvgIcon,
    Container,
    Avatar, Chip, CardHeader,
    Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
}
    from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider, DesktopDatePicker} from "@mui/x-date-pickers";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import {useSearchParams} from "react-router-dom";
import FreeRegisterButton from "./free-register-button";
import AchievementsTable from "../../components/achievements/table";
import PaymentTable from "../../components/payments/table";
import TaskTable from "../../components/tasks/table";
import {mdiArrowExpandDown, mdiChevronDown, mdiInstagram} from "@mdi/js";
import StadiumGeneraleTable from '../../components/stadium-generale/table'
import moment from 'moment'
import 'moment/locale/id'

moment.locale('id')

function TwibbonButton({twibbonUrl}) {
    if (twibbonUrl) {
        return (
            <Button
                href={twibbonUrl}
                target="_blank"
                startIcon={<SvgIcon>
                    <path d={mdiInstagram}/>
                </SvgIcon>}
                variant="contained"
                color="success"
            >
                Lihat Twibbon
            </Button>
        )
    }

    return (
        <Chip
            label="Belum Mengunggah Twibbon"
            variant="contained"
            color="error"
        />
    )
}

function User() {
    const [searchParams, setSearchparams] = useSearchParams()
    const [isEditing, setIsEditing] = useState(false)
    const [profile, _setProfile] = useState({
        email: '',
        name: '',
        phone: '',
        gender: '',
        student_id: '',
        generation: '',
        faculty_id: '',
        study_program_id: '',
        original_address: '',
        current_address: '',
        birth_place: '',
        birth_date: '',
        blood_type: '',
        religion: '',
        organization_experience: '',
        life_motto: '',
        question_restek_know: '',
        question_why_join: '',
        question_target: '',
        is_free_registration: false,
        cashback: false,
        division_select_1: null,
        division_select_2: null,
        subdivision_select_1: null,
        subdivision_select_2: null,
    })
    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [faculties, setFaculties] = useState([])
    const [studyPrograms, setStudyPrograms] = useState([])
    const [tempProfile, setTempProfile] = useState(null)
    const [formReadOnly, setFormReadOnly] = useState(true)

    const userId = searchParams.get('id');

    /**useEffect(() => {
        setFormReadOnly(!isEditing || loading)
    }, [isEditing, loading])**/

    const setProfile = (field, value) => {
        _setProfile((old) => ({...old, [field]: value}))
    }

    useEffect(() => {
        if (profile.faculty_id) {
            setStudyPrograms(faculties.find(faculty => faculty.id == profile.faculty_id)?.study_program || [])
        }
    }, [profile.faculty_id])

    const fetch = async () => {
        try {
            const {data} = await axios.get('/api/admin/user/detail', {params: {id: userId}})

            _setProfile({
                email: data.email,
                name: data.name,
                phone: data.phone,
                gender: data.gender,
                student_id: data.profile?.student_id,
                generation: data.profile?.generation,
                faculty_id: data.profile?.faculty_id,
                study_program_id: data.profile?.study_program_id,
                original_address: data.profile?.original_address,
                current_address: data.profile?.current_address,
                birth_place: data.profile?.birth_place,
                birth_date: data.profile?.birth_date,
                blood_type: data.profile?.blood_type,
                religion: data.profile?.religion,
                organization_experience: data.question?.organization_experience,
                life_motto: data.question?.life_motto,
                question_restek_know: data.question?.question_restek_know,
                question_why_join: data.question?.question_why_join,
                question_target: data.question?.question_target,
                is_free_registration: data.registration?.is_free_registration,
                cashback: Boolean(data.registration?.cashback),
                twibbon_url: data.registration?.twibbon_url,
                division_select_1: data.registration?.division_select1,
                division_select_2: data.registration?.division_select2,
                division_select_1_date: data.registration?.division_select_1_date,
                division_select_2_date: data.registration?.division_select_2_date,
                subdivision_select_1: data.registration?.subdivision_select_1,
                subdivision_select_2: data.registration?.subdivision_select_2,
            })
            setImage(data.image)
        } catch (e) {
            toast('Gagal memuat data', {type: 'error'})
        }
    }

    const fetchFaculty = async () => {
        try {
            const {data} = await axios.get('/api/faculty')

            setFaculties(data)
        } catch (e) {
            toast('Gagal memuat data', {type: 'error'})
        }
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            await Promise.all([fetchFaculty(), fetch()])
            setLoading(false)
        })()
    }, [])

    const handleStudentId = (e) => {
        setProfile('student_id', e.target.value)

        const student_id = String(e.target.value)
        if (student_id.length == 2) {
            setProfile('generation', '20' + student_id)
        } else if (student_id.length == 3) {
            const faculty_id = student_id.substring(2, 3)
            const faculty = faculties.find(f => f.code == faculty_id)
            setProfile('faculty_id', faculty.id)
        } else if (student_id.length == 6) {
            const study_program_id = student_id.substring(3, 6)
            const study_program = studyPrograms.find(s => s.code == study_program_id)
            setProfile('study_program_id', study_program.id)
        }
    }

    return (
        <Container>
            <Grid container justifyContent="center">
                <Grid item>
                    <Avatar alt="User avatar" src={image} sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: 200,
                        height: 200,
                    }}/>
                    <Typography sx={{marginTop: '10px'}}>
                        {profile.email}
                    </Typography>
                </Grid>
            </Grid>
            <Accordion sx={{margin: '20px'}}>
                <AccordionSummary expandIcon={<SvgIcon>
                    <path d={mdiChevronDown}/>
                </SvgIcon>}>
                    <Typography fontSize="20px">
                        Detail Pendaftar
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {loading && <Box sx={{width: '100%'}}>
                        <LinearProgress/>
                    </Box>}
                    <Grid container justifyContent="space-between">
                        {/*<Grid item>*/}
                        {/*    {profile.is_free_registration ?*/}
                        {/*        <Chip*/}
                        {/*            label="Gratis Pendaftaran"*/}
                        {/*            variant="contained"*/}
                        {/*            color="warning"*/}
                        {/*        /> :*/}
                        {/*        <FreeRegisterButton*/}
                        {/*            userId={userId}*/}
                        {/*            size="small"*/}
                        {/*            onSuccess={async () => {*/}
                        {/*                setLoading(true)*/}
                        {/*                await fetch()*/}
                        {/*                setLoading(false)*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*    {profile.cashback &&*/}
                        {/*        <Chip*/}
                        {/*            label="Cashback"*/}
                        {/*            variant="contained"*/}
                        {/*            color="success"*/}
                        {/*            sx={{*/}
                        {/*                marginLeft: 1*/}
                        {/*            }}*/}
                        {/*        />}*/}
                        {/*</Grid>*/}
                        {!loading &&
                            <Grid item>
                                <TwibbonButton twibbonUrl={profile.twibbon_url}/>
                            </Grid>}
                    </Grid>

                    <Grid container style={{marginTop: '20px'}} spacing={2}>
                        {/* Nama Lengkap */}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography>
                                    Nama Lengkap
                                </Typography>
                                <TextField
                                    style={{marginTop: '8px'}}
                                    value={profile.name}
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('name', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Nomor Telepon */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{marginTop: '20px'}}>
                                <Typography>
                                    Nomor Telepon
                                </Typography>
                                <TextField
                                    style={{marginTop: '8px'}}
                                    value={profile.phone}
                                    placeholder="Masukkan nomor telepon"
                                    type="text"
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('phone', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Jenis Kelamin */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{marginTop: '20px'}}>
                                <Typography>
                                    Jenis Kelamin
                                </Typography>
                                <Select
                                    style={{marginTop: '8px'}}
                                    value={profile.gender}
                                    placeholder="Pilih Jenis Kelamin"
                                    displayEmpty
                                    readOnly={formReadOnly}
                                    onChange={(e) => setProfile('gender', e.target.value)}
                                >
                                    <MenuItem value="L">Laki-laki</MenuItem>
                                    <MenuItem value="P">Perempuan</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* NIM */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{marginTop: '20px'}}>
                                <Typography>
                                    Nomor Induk Mahasiswa
                                </Typography>
                                <TextField
                                    style={{marginTop: '8px'}}
                                    value={profile.student_id}
                                    placeholder="Nomor Induk Mahasiswa"
                                    type="number"
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={handleStudentId}
                                />
                            </FormControl>
                        </Grid>

                        {/* Angkatan */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{marginTop: '20px'}}>
                                <Typography>
                                    Angkatan
                                </Typography>
                                <TextField
                                    style={{marginTop: '8px'}}
                                    value={profile.generation}
                                    placeholder="Angkatan"
                                    type="number"
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('generation', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Fakultas */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{marginTop: '20px'}}>
                                <Typography>
                                    Fakultas
                                </Typography>
                                <FormControl>
                                    <Select
                                        style={{marginTop: '8px'}}
                                        value={profile.faculty_id}
                                        displayEmpty
                                        readOnly={formReadOnly}
                                        placeholder="Pilih Fakultas"
                                        onChange={(e) => setProfile('faculty_id', e.target.value)}
                                    >
                                        {faculties.map((faculty) =>
                                            <MenuItem value={faculty.id}>{faculty.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </FormControl>
                        </Grid>

                        {/* Program Studi */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{marginTop: '20px'}}>
                                <Typography>
                                    Program Studi
                                </Typography>
                                <Select
                                    style={{marginTop: '8px'}}
                                    value={profile.study_program_id}
                                    displayEmpty
                                    readOnly={formReadOnly}
                                    placeholder="Pilih Program Studi"
                                    onChange={(e) => setProfile('study_program_id', e.target.value)}
                                >
                                    {studyPrograms.map((studyProgram) =>
                                        <MenuItem value={studyProgram.id}>{studyProgram.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Tempat Lahir */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{marginTop: '20px'}}>
                                <Typography>
                                    Tempat Lahir
                                </Typography>
                                <TextField
                                    style={{marginTop: '8px'}}
                                    value={profile.birth_place}
                                    placeholder="Tempat Lahir"
                                    type="text"
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('birth_place', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Tanggal Lahir */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{marginTop: '20px'}}>
                                <Typography>
                                    Tanggal Lahir
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        inputFormat="MM/dd/yyyy"
                                        value={profile.birth_date}
                                        onChange={(v) => setProfile('birth_date', v)}
                                        readOnly={formReadOnly}
                                        renderInput={(params) => <TextField style={{marginTop: '8px'}} {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        {/* Golongan Darah */}
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{marginTop: '20px'}} fullWidth>
                                <Typography>
                                    Golongan Darah
                                </Typography>
                                <TextField
                                    style={{marginTop: '8px'}}
                                    value={profile.blood_type}
                                    placeholder="Tempat Lahir"
                                    type="text"
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('blood_type', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Agama */}
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{marginTop: '20px'}} fullWidth>
                                <Typography>
                                    Agama
                                </Typography>
                                <Select
                                    style={{marginTop: '8px'}}
                                    value={profile.religion}
                                    placeholder="Pilih Agama"
                                    displayEmpty
                                    readOnly={formReadOnly}
                                    onChange={(e) => setProfile('religion', e.target.value)}
                                >
                                    {['Islam', 'Kristen Protestan', 'Katolik', 'Hindu', 'Buddha', 'Khonghucu', 'Lain-lain'].map((religion) =>
                                        <MenuItem value={religion}>{religion}</MenuItem>,
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Alamat Asal */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{marginTop: '20px'}}>
                                <Typography>
                                    Alamat Asal
                                </Typography>
                                <TextField
                                    multiline
                                    style={{marginTop: '8px'}}
                                    placeholder="Alamat Asal"
                                    value={profile.original_address}
                                    rows={4}
                                    maxrows={6}
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('original_address', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Alamat Saat Ini */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{marginTop: '20px'}}>
                                <Typography>
                                    Alamat Saat ini
                                </Typography>
                                <TextField
                                    multiline
                                    style={{marginTop: '8px'}}
                                    placeholder="Alamat Saat ini"
                                    value={profile.current_address}
                                    rows={4}
                                    maxrows={6}
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('current_address', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container sx={{marginTop: '20px'}}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography>
                                    Apa yang Kamu Ketahui Tentang UKM Rekayasa Teknologi?
                                </Typography>
                                <TextField
                                    multiline
                                    style={{marginTop: '8px'}}
                                    value={profile.question_restek_know}
                                    rows={4}
                                    maxrows={6}
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('question_restek_know', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography sx={{marginTop: '20px'}}>
                                    Mengapa Ingin Bergabung dengan UKM Rekayasa Teknologi?
                                </Typography>
                                <TextField
                                    multiline
                                    style={{marginTop: '8px'}}
                                    value={profile.question_why_join}
                                    rows={4}
                                    maxrows={6}
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('question_why_join', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography sx={{marginTop: '20px'}}>
                                    Apa target yang Ingin Dicapai Jika Diterima?
                                </Typography>
                                <TextField
                                    multiline
                                    style={{marginTop: '8px'}}
                                    value={profile.question_target}
                                    rows={4}
                                    maxrows={6}
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('question_target', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography sx={{marginTop: '20px'}}>
                                    Pengalaman Organisasi
                                </Typography>
                                <TextField
                                    multiline
                                    style={{marginTop: '8px'}}
                                    value={profile.organization_experience}
                                    rows={4}
                                    maxrows={6}
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('organization_experience', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography sx={{marginTop: '20px'}}>
                                    Motto Hidup
                                </Typography>
                                <TextField
                                    multiline
                                    style={{marginTop: '8px'}}
                                    value={profile.life_motto}
                                    rows={4}
                                    maxrows={6}
                                    InputProps={{readOnly: formReadOnly}}
                                    onChange={(e) => setProfile('life_motto', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{margin: '20px'}}>
                <AccordionSummary expandIcon={<SvgIcon>
                    <path d={mdiChevronDown}/>
                </SvgIcon>}>
                    <Typography fontSize="20px">
                        Prestasi
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <AchievementsTable userId={userId} hideUser/>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{margin: '20px'}}>
                <AccordionSummary expandIcon={<SvgIcon>
                    <path d={mdiChevronDown}/>
                </SvgIcon>}>
                    <Typography fontSize="20px">
                        Pembayaran
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <PaymentTable userId={userId} hideUser/>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{margin: '20px'}}>
                <AccordionSummary expandIcon={<SvgIcon>
                    <path d={mdiChevronDown}/>
                </SvgIcon>}>
                    <Typography fontSize="20px">
                        Pilihan Divisi
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    {loading && <Box sx={{width: '100%'}}>
                        <LinearProgress/>
                    </Box>}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Pilihan</TableCell>
                                    <TableCell>Divisi</TableCell>
                                    <TableCell>Sub Divisi</TableCell>
                                    <TableCell>Waktu Seleksi</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Pilihan 1</TableCell>
                                    <TableCell>{profile.division_select_1?.name}</TableCell>
                                    <TableCell>
                                        {profile.subdivision_select_1?.length &&
                                            <ul style={{paddingLeft: '16px'}}>
                                                {profile.subdivision_select_1.map(subdivision => <li key={subdivision.id}>{subdivision.name}</li>)}
                                            </ul>}
                                    </TableCell>
                                    <TableCell>
                                        {profile.division_select_1_date && <Typography>
                                            {moment(profile.division_select_1_date).format('LLLL')}
                                        </Typography>}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Pilihan 2</TableCell>
                                    <TableCell>{profile.division_select_2?.name}</TableCell>
                                    <TableCell>
                                        {profile.subdivision_select_2?.length &&
                                            <ul style={{paddingLeft: '16px'}}>
                                                {profile.subdivision_select_2.map(subdivision => <li key={subdivision.id}>{subdivision.name}</li>)}
                                            </ul>}
                                    </TableCell>
                                    <TableCell>
                                        {profile.division_select_2_date && <Typography>
                                            {moment(profile.division_select_2_date).format('LLLL')}
                                        </Typography>}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{margin: '20px'}}>
                <AccordionSummary expandIcon={<SvgIcon>
                    <path d={mdiChevronDown}/>
                </SvgIcon>}>
                    <Typography fontSize="20px">
                        Stadium Generale
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <StadiumGeneraleTable userId={userId} hideUser />
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{margin: '20px'}}>
                <AccordionSummary expandIcon={<SvgIcon>
                    <path d={mdiChevronDown}/>
                </SvgIcon>}>
                    <Typography fontSize="20px">
                        Penugasan
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <TaskTable userId={userId} hideUser />
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}

export default User;
