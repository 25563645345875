import {
    mdiAccount,
    mdiClipboardCheck,
    mdiTrophy,
    mdiAccountGroup,
    mdiTrophyVariant,
    mdiCurrencyUsd,
    mdiCheckAll,
    mdiClipboardPulse, mdiClipboardList, mdiRedhat,
} from '@mdi/js'
import Registration from '../pages/registration'
import Profile from '../pages/profile'
import Achievements from '../pages/achievements'
import Account from '../pages/account'
import User from '../pages/user'
import Payment from '../pages/payment'
import {SvgIcon} from '@mui/material'
import AdminAchievements from '../pages/admin/achievements'
import AdminPayments from '../pages/admin/payments'
import AdminTasks from '../pages/admin/tasks'
import AdminStadiumGenerale from '../pages/admin/stadium-generale'
import Recapitulation from '../pages/admin/recapitulation'
import Task from '../pages/task'
import SubdivisionSelect from '../pages/registration/subdivision'

export const ROUTES = [
    {
        title: 'Pendaftaran',
        path: '/registration',
        roles: ['student', 'admin', 'guest'],
        component: <Registration/>,
        icon: <SvgIcon>
            <path d={mdiClipboardCheck}/>
        </SvgIcon>
    },
    {
        title: 'Profil',
        path: '/profile',
        roles: ['student'],
        component: <Profile/>,
        icon: <SvgIcon>
            <path d={mdiAccount}/>
        </SvgIcon>
    },
    {
        title: 'Prestasi',
        path: '/achievements',
        roles: ['student'],
        component: <Achievements/>,
        icon: <SvgIcon>
            <path d={mdiTrophy}/>
        </SvgIcon>
    },
    {
        title: 'Pembayaran',
        path: '/payment',
        roles: ['student'],
        component: <Payment/>,
        icon: <SvgIcon>
            <path d={mdiCurrencyUsd}/>
        </SvgIcon>
    },
    {
        title: 'Penugasan',
        path: '/task',
        roles: ['student'],
        component: <Task/>,
        icon: <SvgIcon>
            <path d={mdiClipboardList}/>
        </SvgIcon>
    },
    {
        title: 'List Akun',
        path: '/admin/account',
        roles: ['admin'],
        component: <Account/>,
        icon: <SvgIcon>
            <path d={mdiAccountGroup}/>
        </SvgIcon>
    },
    {
        title: 'Manajemen Prestasi',
        path: '/admin/achievement',
        roles: ['admin'],
        component: <AdminAchievements/>,
        icon: <SvgIcon>
            <path d={mdiTrophy}/>
        </SvgIcon>
    },
    {
        title: 'Pembayaran',
        path: '/admin/payment',
        roles: ['admin'],
        component: <AdminPayments/>,
        icon: <SvgIcon>
            <path d={mdiCurrencyUsd}/>
        </SvgIcon>
    },
    {
        title: 'Stadium Generale',
        path: '/admin/stadium-generale',
        roles: ['admin'],
        component: <AdminStadiumGenerale/>,
        icon: <SvgIcon>
            <path d={mdiCheckAll}/>
        </SvgIcon>
    },
    {
        title: 'Penugasan',
        path: '/admin/task',
        roles: ['admin'],
        component: <AdminTasks/>,
        icon: <SvgIcon>
            <path d={mdiClipboardList}/>
        </SvgIcon>
    },
    {
        title: 'Detail User',
        path: '/user',
        roles: ['admin'],
        component: <User/>,
        disable_menu: true
    },
    {
        title: 'Rekapitulasi',
        path: '/admin/recapitulation',
        roles: ['admin'],
        component: <Recapitulation />,
        icon: <SvgIcon>
            <path d={mdiClipboardPulse} />
        </SvgIcon>
    },
    {
        title: 'Subdivisi',
        path: '/registration/subdivision',
        roles: ['student'],
        component: <SubdivisionSelect />,
        icon: <SvgIcon>
            <path d={mdiRedhat} />
        </SvgIcon>
    }
]
