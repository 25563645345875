import TablePagination from '@mui/material/TablePagination'

export default function({pagination, onChange: setPagination, loading}) {
    return <TablePagination
        SelectProps={{disabled: loading}}
        backIconButtonProps={loading ? {disabled: loading} : undefined}
        nextIconButtonProps={loading ? {disabled: loading} : undefined}
        component="div"
        count={pagination.total}
        page={pagination.current_page - 1}
        onPageChange={(_, page) => setPagination((old) => ({...old, current_page: page + 1}))}
        rowsPerPage={pagination.per_page}
        onRowsPerPageChange={(e) => setPagination((old) => ({
            ...old,
            per_page: e.target.value
        }))}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
    />
}