import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
    MenuItem,
    FormControl,
    Select,
    LinearProgress, SvgIcon, Container, Avatar,
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider, DesktopDatePicker} from "@mui/x-date-pickers";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import {mdiCancel, mdiContentSave, mdiPencil} from "@mdi/js";
import {useLocation} from "react-router-dom";


function InputData () {
    return (
        <TextField
            id="outlined-read-only-input"
            defaultValue="Hello World"
            InputProps={{}}
        />
    )
}

function InputData2 () {
    return (
        <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            maxrows={6}
            InputProps={{}}
        />
    )
}

function InputData3 () {
    return (
        <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            maxrows={6}
            InputProps={{}}
        />
    )
}

function Profile () {
    const [age, setAge] = useState('')
    const [value, setValue] = useState(new Date('2022-08-17'))
    const [agama, setAgama] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    const [profile, _setProfile] = useState({
        name: '',
        phone: '',
        gender: '',
        student_id: '',
        generation: '',
        faculty_id: '',
        study_program_id: '',
        original_address: '',
        current_address: '',
        birth_place: '',
        birth_date: '',
        blood_type: '',
        religion: '',
        organization_experience: '',
        life_motto: '',
        question_restek_know: '',
        question_why_join: '',
        question_target: '',
    })
    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [faculties, setFaculties] = useState([])
    const [studyPrograms, setStudyPrograms] = useState([])
    const [tempProfile, setTempProfile] = useState(null)
    const [formReadOnly, setFormReadOnly] = useState(true)
    const location = useLocation()

    useEffect(() => {
        setFormReadOnly(!isEditing || loading)
    }, [isEditing, loading])

    const setProfile = (field, value) => {
        _setProfile((old) => ({ ...old, [field]: value }))
    }

    const dateChange = (newValue) => {
        setValue(newValue);
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const agamaChange = (newEvent) => {
        setAgama(newEvent.target.value);
    }

    useEffect(() => {
        if (profile.faculty_id) {
            setStudyPrograms(faculties.find(faculty => faculty.id == profile.faculty_id)?.study_program || [])
        }
    }, [profile.faculty_id])

    const fetch = async () => {
        try {
            const { data } = await axios.get('/api/user/profile')

            _setProfile({
                name: data.name,
                phone: data.phone,
                gender: data.gender,
                student_id: data.student_id,
                generation: data.generation,
                faculty_id: data.faculty_id,
                study_program_id: data.study_program_id,
                original_address: data.original_address,
                current_address: data.current_address,
                birth_place: data.birth_place,
                birth_date: data.birth_date,
                blood_type: data.blood_type,
                religion: data.religion,
                organization_experience: data.organization_experience,
                life_motto: data.life_motto,
                question_restek_know: data.question_restek_know,
                question_why_join: data.question_why_join,
                question_target: data.question_target,
            })
            setImage(data.image)
        } catch (e) {
            toast('Gagal memuat data', { type: 'error' })
        }
    }

    const fetchFaculty = async () => {
        try {
            const { data } = await axios.get('/api/faculty')

            setFaculties(data)
        } catch (e) {
            toast('Gagal memuat data', { type: 'error' })
        }
    }

    useEffect(() => {
        (async () => {
            await fetchFaculty()
            await fetch()
        })()

        const prompt = new URLSearchParams(location.search).get('prompt')
        if (prompt) {
            toast('Silahkan Masukkan Data Diri')
        }
    }, [])

    const saveEdit = async () => {
        setLoading(true)
        try {
            const payload = { ...profile }
            payload.phone = payload.phone ? payload.phone.replace(/^(\+62)/, '62').replace(/^0/, '62') : null
            payload.birth_date = payload.birth_date ? new Date(payload.birth_date).toISOString().split('T')[0] : null
            const { status } = await axios.post('/api/user/profile/update', payload)
            if (status === 200) {
                toast('Data berhasil disimpan', { type: 'success' })
                setIsEditing(false)

                const prompt = new URLSearchParams(location.search).get('prompt')
                if (prompt) {
                    setTimeout(() => {
                        window.location = '/registration'
                    }, 1000)
                } else {
                    fetch()
                }
            }
        } catch (e) {
            console.log(e)
            if (e.response?.status === 422) {
                return toast(e.response.data?.message, { type: 'error' })
            }
            toast('Gagal menyimpan data', { type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const editProfile = () => {
        setTempProfile(JSON.stringify(profile))
        setIsEditing(true)
    }

    const cancelEdit = () => {
        _setProfile(JSON.parse(tempProfile))
        setTempProfile(null)
        setIsEditing(false)
    }

    const handleStudentId = (e) => {
        setProfile('student_id', e.target.value)

        const student_id = String(e.target.value)
        if (student_id.length === 2) {
            setProfile('generation', '20' + student_id)
        } else if (student_id.length === 3) {
            const faculty_id = student_id.substring(2, 3)
            const faculty = faculties.find(f => f.code == faculty_id)
            setProfile('faculty_id', faculty.id)
        } else if (student_id.length === 6) {
            const study_program_id = student_id.substring(3, 6)
            const study_program = studyPrograms.find(s => s.code == study_program_id)
            setProfile('study_program_id', study_program.id)
        }
    }

    return (
        <Container>
            <Grid container justifyContent="center">
                <Grid item>
                    <Avatar alt="User avatar" src={image} sx={{ width: 200, height: 200 }}/>
                </Grid>
            </Grid>
            <Card sx={{ margin: '20px', borderRadius: '12px' }}>
                {loading && <Box sx={{ width: '100%' }}>
                    <LinearProgress/>
                </Box>}
                <CardContent>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography fontSize="20px">
                                Profil
                            </Typography>
                        </Grid>
                        <Grid item>
                            {isEditing ?
                                (<>
                                    <Button
                                        style={{ borderRadius: '12px' }}
                                        variant="contained"
                                        color="error"
                                        startIcon={
                                            <SvgIcon>
                                                <path d={mdiCancel}/>
                                            </SvgIcon>
                                        }
                                        onClick={cancelEdit}
                                        sx={{ marginRight: 1 }}
                                    >
                                        Batal
                                    </Button>
                                    <Button
                                        style={{ borderRadius: '12px' }}
                                        variant="contained"
                                        color="success"
                                        startIcon={
                                            <SvgIcon>
                                                <path d={mdiContentSave}/>
                                            </SvgIcon>
                                        }
                                        onClick={saveEdit}
                                    >
                                        Simpan
                                    </Button>
                                </>) :
                                <Button
                                    style={{ borderRadius: '12px' }}
                                    variant="contained"
                                    color="warning"
                                    startIcon={
                                        <SvgIcon>
                                            <path d={mdiPencil}/>
                                        </SvgIcon>
                                    }
                                    onClick={editProfile}
                                >
                                    Edit
                                </Button>
                            }
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: '20px' }} spacing={2}>
                        {/* Nama Lengkap */}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography>
                                    Nama Lengkap
                                </Typography>
                                <TextField
                                    style={{ marginTop: '8px' }}
                                    value={profile.name}
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('name', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Nomor Telepon */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{ marginTop: '20px' }}>
                                <Typography>
                                    Nomor Telepon
                                </Typography>
                                <TextField
                                    style={{ marginTop: '8px' }}
                                    value={profile.phone}
                                    placeholder="Masukkan nomor telepon"
                                    type="text"
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('phone', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Jenis Kelamin */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{ marginTop: '20px' }}>
                                <Typography>
                                    Jenis Kelamin
                                </Typography>
                                <Select
                                    style={{ marginTop: '8px' }}
                                    value={profile.gender}
                                    placeholder="Pilih Jenis Kelamin"
                                    displayEmpty
                                    disabled={formReadOnly}
                                    onChange={(e) => setProfile('gender', e.target.value)}
                                >
                                    <MenuItem value="L">Laki-laki</MenuItem>
                                    <MenuItem value="P">Perempuan</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* NIM */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{ marginTop: '20px' }}>
                                <Typography>
                                    Nomor Induk Mahasiswa
                                </Typography>
                                <TextField
                                    style={{ marginTop: '8px' }}
                                    value={profile.student_id}
                                    placeholder="Nomor Induk Mahasiswa"
                                    type="number"
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={handleStudentId}
                                />
                            </FormControl>
                        </Grid>

                        {/* Angkatan */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{ marginTop: '20px' }}>
                                <Typography>
                                    Angkatan
                                </Typography>
                                <TextField
                                    style={{ marginTop: '8px' }}
                                    value={profile.generation}
                                    placeholder="Angkatan"
                                    type="number"
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('generation', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Fakultas */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{ marginTop: '20px' }}>
                                <Typography>
                                    Fakultas
                                </Typography>
                                <FormControl>
                                    <Select
                                        style={{ marginTop: '8px' }}
                                        value={profile.faculty_id}
                                        displayEmpty
                                        placeholder="Pilih Fakultas"
                                        disabled={formReadOnly}
                                        onChange={(e) => setProfile('faculty_id', e.target.value)}
                                    >
                                        {faculties.map((faculty) =>
                                            <MenuItem value={faculty.id}>{faculty.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </FormControl>
                        </Grid>

                        {/* Program Studi */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{ marginTop: '20px' }}>
                                <Typography>
                                    Program Studi
                                </Typography>
                                <Select
                                    style={{ marginTop: '8px' }}
                                    value={profile.study_program_id}
                                    displayEmpty
                                    disabled={formReadOnly}
                                    placeholder="Pilih Program Studi"
                                    onChange={(e) => setProfile('study_program_id', e.target.value)}
                                >
                                    {studyPrograms.map((studyProgram) =>
                                        <MenuItem value={studyProgram.id}>{studyProgram.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Tempat Lahir */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{ marginTop: '20px' }}>
                                <Typography>
                                    Tempat Lahir
                                </Typography>
                                <TextField
                                    style={{ marginTop: '8px' }}
                                    value={profile.birth_place}
                                    placeholder="Tempat Lahir"
                                    type="text"
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('birth_place', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Tanggal Lahir */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{ marginTop: '20px' }}>
                                <Typography>
                                    Tanggal Lahir
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={profile.birth_date}
                                        onChange={(v) => setProfile('birth_date', v)}
                                        disabled={formReadOnly}
                                        InputProps={{ readOnly: formReadOnly }}
                                        renderInput={(params) => <TextField style={{ marginTop: '8px' }} {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        {/* Golongan Darah */}
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ marginTop: '20px' }} fullWidth>
                                <Typography>
                                    Golongan Darah
                                </Typography>
                                <TextField
                                    style={{ marginTop: '8px' }}
                                    value={profile.blood_type}
                                    placeholder="Golongan Darah"
                                    type="text"
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('blood_type', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Agama */}
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ marginTop: '20px' }} fullWidth>
                                <Typography>
                                    Agama
                                </Typography>
                                <Select
                                    style={{ marginTop: '8px' }}
                                    value={profile.religion}
                                    placeholder="Pilih Agama"
                                    displayEmpty
                                    disabled={formReadOnly}
                                    onChange={(e) => setProfile('religion', e.target.value)}
                                >
                                    {['Islam', 'Kristen Protestan', 'Katolik', 'Hindu', 'Buddha', 'Khonghucu', 'Lain-lain'].map((religion) =>
                                        <MenuItem value={religion}>{religion}</MenuItem>,
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Alamat Asal */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{ marginTop: '20px' }}>
                                <Typography>
                                    Alamat Asal
                                </Typography>
                                <TextField
                                    multiline
                                    style={{ marginTop: '8px' }}
                                    placeholder="Alamat Asal"
                                    value={profile.original_address}
                                    rows={4}
                                    maxrows={6}
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('original_address', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Alamat Saat Ini */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={{ marginTop: '20px' }}>
                                <Typography>
                                    Alamat Saat ini
                                </Typography>
                                <TextField
                                    multiline
                                    style={{ marginTop: '8px' }}
                                    placeholder="Alamat Saat ini"
                                    value={profile.current_address}
                                    rows={4}
                                    maxrows={6}
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('current_address', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ marginTop: '20px' }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography>
                                    Apa yang Kamu Ketahui Tentang UKM Rekayasa Teknologi?
                                </Typography>
                                <TextField
                                    multiline
                                    style={{ marginTop: '8px' }}
                                    value={profile.question_restek_know}
                                    rows={4}
                                    maxrows={6}
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('question_restek_know', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography sx={{ marginTop: '20px' }}>
                                    Mengapa Ingin Bergabung dengan UKM Rekayasa Teknologi?
                                </Typography>
                                <TextField
                                    multiline
                                    style={{ marginTop: '8px' }}
                                    value={profile.question_why_join}
                                    rows={4}
                                    maxrows={6}
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('question_why_join', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography sx={{ marginTop: '20px' }}>
                                    Apa target yang Ingin Dicapai Jika Diterima?
                                </Typography>
                                <TextField
                                    multiline
                                    style={{ marginTop: '8px' }}
                                    value={profile.question_target}
                                    rows={4}
                                    maxrows={6}
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('question_target', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography sx={{ marginTop: '20px' }}>
                                    Pengalaman Organisasi
                                </Typography>
                                <TextField
                                    multiline
                                    style={{ marginTop: '8px' }}
                                    value={profile.organization_experience}
                                    rows={4}
                                    maxrows={6}
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('organization_experience', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography sx={{ marginTop: '20px' }}>
                                    Motto Hidup
                                </Typography>
                                <TextField
                                    multiline
                                    style={{ marginTop: '8px' }}
                                    value={profile.life_motto}
                                    rows={4}
                                    maxrows={6}
                                    disabled={formReadOnly}
                                    InputProps={{ readOnly: formReadOnly }}
                                    onChange={(e) => setProfile('life_motto', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    )
}

export default Profile;
