import {
    Card,
    CardContent,
    Button,
    Grid,
    Typography,
    Chip,
    Container,
    FormControl,
    Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions,
} from "@mui/material"
import {useState, useEffect} from "react"
import {mdiUpload, mdiTrashCan} from "@mdi/js"
import {SvgIcon} from "@mui/material";
import {Box} from "@mui/system";
import axios from "axios";
import {toast} from "react-toastify";
import {LoadingButton} from '@mui/lab'

function Task() {
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState()
    const [already, setAlready] = useState(false)
    const [info, setInfo] = useState({})
    const [preview, setPreview] = useState(null)
    const [form, setForm] = useState({
        file: '',
        id: null,
    })

    useEffect(() => {
        if (!form.file) {
            setPreview(null)
            return
        }

        if (typeof form.file === 'string') {
            setPreview(axios.defaults.baseURL + '/api/download?file=' + form.file)
        } else {
            const objectUrl = URL.createObjectURL(form.file)
            setPreview(objectUrl)

            return () => URL.revokeObjectURL(objectUrl)
        }
    }, [form.file])

    const taskInfo = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/task/info')
            setInfo(data)
        } catch (e) {
            toast('Gagal memuat data', {
                type: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    const fetch = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/task/list')
            if (data.length !== 0) {
                setStatus(data[0].status)
                setAlready(true)
                setForm({
                    id: data[0].id,
                    file: data[0].file,
                })
            } else {
                setAlready(false)
            }

        } catch (e) {
            toast('Gagal memuat data', {
                type: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    const create = async () => {
        setLoading(true)
        try {
            const payload = new FormData()
            payload.append('file', typeof form.file !== 'string' ? form.file : null)

            const {status, data} = await axios.post('/api/task/create', payload)

            if (status === 201) {
                toast('Berhasil mengunggah file')
                window.location.reload()
            }
        } catch (e) {
            console.log(e)
            toast('Gagal memuat file', {
                type: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetch()
        taskInfo()
    }, [])

    const handleFileUpload = (e) => {
        if (!e.target.files?.length) {
            return
        }

        const file = e.target.files[0]
        setForm(old => ({...old, file}))
    }

    const removeFile = () => {
        if (already) {
            setShowDeletePrompt(true)
        } else {
            setForm(old => ({...old, delete_file: true, file: null}))
        }
    }

    const deleteFile = async () => {
        setDeleteLoading(true)
        try {
            const {status} = await axios.post('/api/task/delete', {id: form.id})
            if (status === 204) {
                toast('Berhasil menghapus file', {type: 'success'})
                setForm({id: null, file: null})
                fetch()
            }
        } catch (e) {
            toast('Gagal menghapus file', {type: 'error'})
        } finally {
            setDeleteLoading(false)
            setShowDeletePrompt(false)
        }
    }

    const [deleteLoading, setDeleteLoading] = useState(false)
    const [showDeletePrompt, setShowDeletePrompt] = useState(false)
    const handleCloseDeletePrompt = () => {
        setShowDeletePrompt(false)
    }

    return (
        <Container>
            <Dialog
                open={showDeletePrompt}
                onClose={handleCloseDeletePrompt}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Hapus File
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Anda yakin ingin menghapus file ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={deleteLoading} variant="contained" color="info" onClick={handleCloseDeletePrompt}>
                        Batal
                    </Button>
                    <LoadingButton loading={deleteLoading} variant="outlined" color="error" onClick={deleteFile}>
                        Hapus
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Grid container sx={{justifyContent: 'center', marginTop: '20px'}}>
                <Grid item>
                    <Card sx={{minWidth: 800, maxWidth: 800, borderRadius: 3}}>
                        <CardContent>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">
                                        Penugasan
                                    </Typography>
                                </Grid>
                                {already && <Grid item>
                                    {status === 'accepted' ?
                                        <Chip label="Diterima" color="success"/> :
                                        (status === 'rejected' ?
                                            <Chip label="Ditolak" color="error"/> :
                                            <Chip label="Menunggu" color="warning"/>)
                                    }
                                </Grid>}
                            </Grid>
                            <Grid container sx={{marginTop: '20px', justifyContent: "center"}}>
                                <Grid item xs={12}>
                                    <Typography sx={{whiteSpace: 'pre-wrap'}}>
                                        {info?.task_detail}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{marginTop: '20px'}}>
                                    <FormControl fullWidth>
                                        {!form.file ?
                                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                                {!already &&
                                                    <Button sx={{marginX: 1}}
                                                            variant="contained"
                                                            component="label"
                                                            startIcon={
                                                                <SvgIcon>
                                                                    <path d={mdiUpload}/>
                                                                </SvgIcon>
                                                            }
                                                            sx={{
                                                                marginX: 'auto'
                                                            }}
                                                    >
                                                        Tambah File
                                                        <input
                                                            type="file"
                                                            hidden
                                                            accept=".pdf"
                                                            onChange={handleFileUpload}
                                                        />
                                                    </Button>}

                                            </Box> :
                                            <>
                                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={removeFile}
                                                        disabled={loading}
                                                        style={{marginRight: '12px'}}
                                                        startIcon={
                                                            <SvgIcon>
                                                                <path d={mdiTrashCan}/>
                                                            </SvgIcon>
                                                        }
                                                    >
                                                        Hapus File
                                                    </Button>

                                                    {!already &&
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                paddingX: 2,
                                                                marginX: 1,
                                                            }}
                                                            onClick={create}
                                                        >
                                                            Simpan
                                                        </Button>
                                                    }

                                                </Box>
                                            </>}
                                        {preview &&
                                            <iframe style={{marginTop: '12px'}} src={preview} width="100%" height="400px" frameBorder={0}/>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Task;
