import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid,
    LinearProgress, MenuItem, Select,
    SvgIcon, TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import {mdiCheck, mdiClose, mdiContentSave, mdiOpenInNew} from "@mdi/js";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

const STATUS = [
    {
        name: 'Tidak Aktif',
        value: 'inactive',
    },
    {
        name: 'Berlangsung',
        value: 'ongoing',
    },
    {
        name: 'Selesai',
        value: 'passed'
    }
]

function TimelineForm({editId, onClose, onSuccess}) {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [timeline, _setTimeline] = useState({
        title: '',
        description: '',
        start_date: '',
        end_date: '',
        status: 'inactive'
    })
    const [config, setConfig] = useState([])

    useEffect(() => {
        if (editId) {
            setShow(true)
            fetch()
        } else {
            setShow(false)
            resetForm()
        }
    }, [editId])

    const fetch = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/admin/timeline/detail', {
                params: {
                    id: editId
                }
            })
            _setTimeline({
                title: data.title,
                description: data.description,
                start_date: data.start_date,
                end_date: data.end_date,
                status: data.status,
            })
            data.timeline_config && setConfig(data.timeline_config)
        } catch (e) {
            toast('Gagal memuat data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    const save = async () => {
        setLoading(true)
        try {
            const {status} = await axios.post('/api/admin/timeline/update', {
                id: editId,
                config: config,
                ...timeline,
            })
            if (status === 200) {
                toast('Berhasil memperbarui data', {type: 'success'})
                onSuccess?.()
            }
        } catch (e) {
            toast('Gagal memperbarui data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    const setTimeline = (field, value) => {
        _setTimeline(old => ({...old, [field]: value}))
    }

    const resetForm = () => {
        _setTimeline({
            title: '',
            description: '',
            start_date: '',
            end_date: '',
            status: 'inactive'
        })
    }

    return (
        <>
            <Dialog open={show} onClose={onClose} scroll="paper" fullWidth maxWidth="md">
                {loading && <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>}
                <DialogTitle>
                    <Box sx={{display: 'flex'}}>
                        <div style={{marginRight: 'auto'}}>
                            Edit Timeline
                        </div>
                    </Box>
                </DialogTitle>
                <DialogContent style={{paddingBottom: 0}}>
                    <Grid container spacing={2}>
                        {/*Judul*/}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography>
                                    Judul
                                </Typography>
                                <TextField
                                    style={{marginTop: '8px'}}
                                    value={timeline.title}
                                    disabled={loading}
                                    onChange={(e) => setTimeline('title', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/*Status*/}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography>
                                    Status
                                </Typography>
                                <Select
                                    style={{marginTop: '8px'}}
                                    value={timeline.status}
                                    placeholder="Pilih Status"
                                    displayEmpty
                                    disabled={loading}
                                    onChange={(e) => setTimeline('status', e.target.value)}
                                >
                                    {STATUS.map((status) =>
                                        <MenuItem key={status.value} value={status.value}>{status.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/*Tanggal Mulai*/}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Typography>
                                    Tanggal Mulai
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={timeline.start_date}
                                        onChange={(v) => setTimeline('start_date', v)}
                                        disabled={loading}
                                        renderInput={(params) => <TextField style={{marginTop: '8px'}} {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        {/*Tanggal Selesai*/}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Typography>
                                    Tanggal Selesai
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={timeline.end_date}
                                        onChange={(v) => setTimeline('end_date', v)}
                                        disabled={loading}
                                        renderInput={(params) => <TextField style={{marginTop: '8px'}} {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        {/*Deskripsi*/}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography>
                                    Deskripsi
                                </Typography>
                                <TextField
                                    multiline
                                    style={{marginTop: '8px'}}
                                    value={timeline.description}
                                    rows={4}
                                    maxrows={6}
                                    disabled={loading}
                                    onChange={(e) => setTimeline('description', e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {/*Konfigurasi*/}
                        {config.map((item, i) => (
                            <Grid key={i} item xs={12}>
                                <FormControl fullWidth>
                                    <Typography>
                                        {item.name}
                                    </Typography>
                                    <TextField
                                        multiline
                                        style={{marginTop: '8px'}}
                                        value={item.value}
                                        rows={4}
                                        maxrows={6}
                                        disabled={loading}
                                        onChange={(e) => setConfig(old => old.map(v => {
                                            if (v.id === item.id) {
                                                return { ...v, value: e.target.value }
                                            }
                                            return v
                                        }))}
                                    />
                                </FormControl>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={onClose}
                        disabled={loading}
                    >
                        Tutup
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        startIcon={<SvgIcon>
                            <path d={mdiContentSave}/>
                        </SvgIcon>}
                        onClick={save}
                    >
                        Simpan
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TimelineForm