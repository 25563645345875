import {
    Card,
    CardContent,
    CardHeader,
    Container
} from "@mui/material";
import TaskTable from "../../../components/tasks/table";

function AdminTasks() {
    return (
        <Container>
            <Card border="light" style={{borderRadius: '15px'}}>
                <CardHeader title="Penugasan"/>

                <CardContent>
                    <TaskTable />
                </CardContent>
            </Card>
        </Container>
    )
}

export default AdminTasks
