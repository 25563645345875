import React, {useEffect, useState} from 'react';
import Layout from "./components/layout";
import {Navigate, Route, Routes} from "react-router-dom"
import {ROUTES} from "./contants/pages";
import useAuth from "./context/auth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const auth = useAuth()

    const routes = ROUTES.filter(route => route.roles.includes(auth.user.role || 'guest')).map((route, i) => (
        <Route key={i} path={route.path} element={route.component}/>
    ))

    return (
        <>
            <Layout>
                <Routes>
                    {routes}
                    {!auth.loading && <Route path="*" element={<Navigate to="/registration" replace />} />}
                </Routes>
                <ToastContainer icon={false}  />
            </Layout>
        </>
    );
}

export default App;
