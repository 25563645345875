import {addLinks} from "react-link-text";
import {blue} from "@mui/material/colors";
import * as React from "react";

const URL_REGEX = /(?:(?:http|https):\/\/)?([-a-zA-Z0-9.]{2,256}\.[a-z]{2,4})\b(?:\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/
const URL_REGEX_SIMPLE = /^(http:\/\/)|^(https:\/\/)/
export const addLinks2 = (text) => {
    return addLinks(text, {
        regex: URL_REGEX,
        component: (url, text, key, className) => <a href={textToUrl(url)} target="_blank" style={{
            color: blue[300],
            textDecoration: 'none'
        }} className={className}>{text}</a>
    })
}
export const textToUrl = (text) => {
    if (!text) {
        return '#'
    }
    return (!URL_REGEX_SIMPLE.test(text) ? 'https://' : '') + text
}