import {useEffect, useState} from "react";
import axios from 'axios';
import {
    mdiClose,
    mdiDotsVertical,
    mdiCheck,
} from "@mdi/js";
import {
    Avatar,
    Card,
    CardContent,
    CardHeader, Chip,
    Container, Grid, IconButton, LinearProgress, Pagination,
    Paper, Select,
    SvgIcon,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    FormControl,
    TextField,
    InputLabel,
    MenuItem,
    Tooltip,
    Menu,
    ListItemIcon,
    ListItemText,

} from "@mui/material";
import {toast} from "react-toastify";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AchievementDetail from "../admin/achievements/detail";
import TablePagination from "../../components/common/table/TablePagination";
import ExportRegistrationButton from "../../components/service/ExportRegistration";
import moment from 'moment'
import 'moment/locale/id'
import * as React from 'react'
import FilterColumn from "../../components/common/table/FitlerColumn";
import UserProfileCell from "../../components/common/table/UserProfileCell";

moment.locale('id')

const HEADERS = [
    'Mahasiswa',
    'Pilihan 1',
    'Pilihan 2',
    'NIA',
    'Aksi',
]

function StatusChip({ status, ...props }) {
    switch (status) {
        case 'Diterima':
            return <SvgIcon {...props} color="success"><path d={mdiCheck} /></SvgIcon>
        case 'Ditolak':
            return <SvgIcon {...props} color="error"><path d={mdiClose} /></SvgIcon>
        default:
            break
    }

    return null
}

function Account () {
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 5,
        last_page: 0,
        total: 0,
        prev: false,
        next: true,
        total_page: 0,
    })
    const [detailId, setDetailId] = useState(null)
    const [search, setSearch] = useState('')
    const [searchTimeout, setSearchTimeout] = useState(null)
    const [divisionId, setDivisionId] = useState(null)
    const [divisions, setDivisions] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [openMenu, setOpenMenu] = useState(false)
    const [selectedId, setSelectedId] = useState(null)
    const [loadingDivision, setLoadingDivision] = useState(false)
    const [headers, setHeaders] = useState(HEADERS)
    // const [searchParams, setSearchParams] = useSearchParams()

    const fetchDivision = async () => {
        setLoadingDivision(true)
        try {
            const { data } = await axios.get('/api/division')
            setDivisions(data)
        } catch (e) {
            toast('Gagal memuat data', { type: 'error' })
        } finally {
            setLoadingDivision(false)
        }
    }

    const onMenuClick = (e, id) => {
        setAnchorEl(e.currentTarget)
        setOpenMenu(true)
        setSelectedId(id)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        setSelectedId(null)
        setOpenMenu(false)
    }

    const newMember = async (accept) => {
        const id = selectedId
        handleMenuClose()
        setLoading(true)
        try {
            const { status } = await axios.post('/api/admin/registration/accept-main-member', {
                user_id: id,
                accept: !!accept,
            })

            if (status === 200) {
                toast('Berhasil memperbarui data', { type: 'success' })
                fetch()
            }
        } catch (e) {
            toast('Gagal memperbarui data', { type: 'error' })
            setLoading(false)
        }
    }

    const updateStatus = async ({ accept, selection }) => {
        const id = selectedId
        handleMenuClose()
        setLoading(true)
        try {
            const { status } = await axios.post('/api/admin/registration/update-status', {
                user_id: id,
                accept,
                selection
            })

            if (status === 200) {
                toast('Berhasil memperbarui data', { type: 'success' })
                fetch()
            }
        } catch (e) {
            toast('Gagal memperbarui data', { type: 'error' })
            setLoading(false)
        }
    }

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }
        setSearchTimeout(setTimeout(() => {
            fetch()
            setSearchTimeout(null)
        }, 1_000))
    }, [search])

    const fetch = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get('/api/admin/user/list', {
                params: {
                    page: pagination.current_page,
                    per_page: pagination.per_page,
                    search: search,
                    division: divisionId,
                },
            })

            if (data) {
                setPagination({
                    current_page: data.current_page,
                    per_page: data.per_page,
                    last_page: data.last_page,
                    prev: Boolean(data.prev),
                    next: Boolean(data.next),
                    total: data.total,
                    total_page: Math.ceil(data.total / data.per_page),
                })
                setItems(data.data)
            }
        } catch (e) {
            toast('Gagal memuat data', {
                type: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    const parseSubdivision = (divId, subdivId) => {
        const subdivision = divisions.find(division => division.id == divId)?.subdivision || []
        const subdivIds = subdivId.split(',').map(Number)
        return <ul style={{paddingLeft: '16px'}}>
            {subdivision.filter(subdiv => subdivIds.includes(subdiv.id)).map(subdiv =>
                <li key={subdiv.id}>{subdiv.name}</li>)}
        </ul>
    }

    useEffect(() => {
        fetchDivision()
        // const search_division_id = Number(searchParams.get('division_id'))
        // if (search_division_id) {
        //     setDivisionId(search_division_id)
        // }
    }, [])

    useEffect(() => {
        fetch()
    }, [pagination.per_page, pagination.current_page, divisionId])

    return (
        <Container>
            {!loadingDivision &&
                <Card border="light" style={{ borderRadius: '15px' }}>
                    <CardHeader
                        title="List Pendaftar"
                    />

                    <AchievementDetail id={detailId} onClose={() => setDetailId(null)} />

                    <CardContent>
                        <Grid container sx={{ marginBottom: 2 }} spacing={2} alignItems="center">
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="search"
                                        size="small"
                                        label="Cari"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel size="small" id="select-division">Pilihan Divisi</InputLabel>
                                    <Select
                                        labelId="select-division"
                                        size="small"
                                        value={divisionId}
                                        label="Pilihan Divisi"
                                        onChange={e => setDivisionId(e.target.value)}
                                        endAdornment={divisionId &&
                                            <IconButton onClick={() => setDivisionId(null)}>
                                                <SvgIcon>
                                                    <path d={mdiClose} />
                                                </SvgIcon>
                                            </IconButton>}
                                        sx={{
                                            "& .MuiSelect-iconOutlined": { display: divisionId ? 'none' : '' },
                                        }}
                                    >
                                        {divisions.map((division, i) =>
                                            <MenuItem dense key={i} value={division.id}>{division.name}</MenuItem>,
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm="auto">
                                <FilterColumn columnList={HEADERS} selectedList={headers} setSelected={setHeaders}/>
                            </Grid>

                            <Grid item sx={{ display: 'flex', marginLeft: 'auto' }}>
                                <div>
                                    <ExportRegistrationButton search={search} divisions={divisions} divisionId={divisionId} variant="contained" />
                                </div>
                            </Grid>

                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel id="select-status">Status</InputLabel>*/}
                            {/*        <Select*/}
                            {/*            labelId="select-status"*/}
                            {/*            value={status}*/}
                            {/*            label="Status"*/}
                            {/*            onChange={e => setStatus(e.target.value)}*/}
                            {/*            endAdornment={status &&*/}
                            {/*                <IconButton onClick={() => setStatus(null)}>*/}
                            {/*                    <SvgIcon>*/}
                            {/*                        <path d={mdiClose}/>*/}
                            {/*                    </SvgIcon>*/}
                            {/*                </IconButton>}*/}
                            {/*            sx={{*/}
                            {/*                "& .MuiSelect-iconOutlined": {display: status ? 'none' : ''}*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <MenuItem dense value="accepted">Diterima</MenuItem>*/}
                            {/*            <MenuItem dense value="pending">Menunggu</MenuItem>*/}
                            {/*            <MenuItem dense value="rejected">Ditolak</MenuItem>*/}
                            {/*        </Select>*/}
                            {/*    </FormControl>*/}
                            {/*</Grid>*/}
                        </Grid>

                        <TableContainer component={Paper}>
                            {loading && <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>}
                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableCell>{header}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {items.map((item) => (
                                        <TableRow key={item.id}>
                                            {headers.includes('Mahasiswa') && <TableCell>
                                                <UserProfileCell
                                                    userId={item.id}
                                                    userName={item.name}
                                                    userImage={item.image}
                                                    studentId={item.profile?.student_id}
                                                    generation={item.profile?.generation}
                                                    studyProgramLevel={item.profile?.study_program?.level}
                                                    studyProgramName={item.profile?.study_program?.name}
                                                    facultyName={item.profile?.faculty?.name}
                                                    isFreeRegistration={item.registration?.is_free_registration}
                                                    isCashbackGiven={item.registration?.cashback}
                                                />
                                            </TableCell>}
                                            {headers.includes('Pilihan 1') && <TableCell>
                                                <div>
                                                    {item.registration.division_select1 && <>
                                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                            {item.registration.division_select1.name}
                                                            <StatusChip status={item.registration.accepted_1} sx={{marginLeft: 1}} />
                                                        </Typography>
                                                        {/*{item.registration.subdivision_select_1_id?.length && parseSubdivision(item.registration.division_select1.id, item.registration.subdivision_select_1_id)}*/}
                                                        {item.registration.division_select_1_date &&
                                                            <Typography variant="body1">
                                                                *{moment(item.registration.division_select_1_date).format('LLLL')}
                                                            </Typography>
                                                        }
                                                    </>}
                                                </div>
                                            </TableCell>}
                                            {headers.includes('Pilihan 2') && <TableCell>
                                                <div>
                                                    {item.registration.division_select2 && <>
                                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                            {item.registration.division_select2.name}
                                                            <StatusChip status={item.registration.accepted_2} sx={{marginLeft: 1}} />
                                                        </Typography>
                                                        {/*{item.registration.subdivision_select_2_id?.length && parseSubdivision(item.registration.division_select2.id, item.registration.subdivision_select_2_id)}*/}
                                                        {item.registration.division_select_2_date &&
                                                            <Typography variant="body1">
                                                                *{moment(item.registration.division_select_2_date).format('LLLL')}
                                                            </Typography>
                                                        }
                                                    </>}
                                                </div>
                                            </TableCell>}
                                            {headers.includes('NIA') && <TableCell>
                                                {item.registration.member_number?.member_id || '-'}
                                            </TableCell>}
                                            {headers.includes('Aksi') && <TableCell>
                                                <IconButton onClick={(e) => onMenuClick(e, item.id)} variant="contained">
                                                    <SvgIcon>
                                                        <path d={mdiDotsVertical} />
                                                    </SvgIcon>
                                                </IconButton>
                                            </TableCell>}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Menu
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleMenuClose}
                        >
                            <MenuItem dense onClick={() => updateStatus({ accept: true, selection: 1 })} disabled={items.find(item => item.id === selectedId)?.registration.accepted_1 === 'Diterima'}>
                                <ListItemIcon>
                                    <SvgIcon>
                                        <path d={mdiCheck} />
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText>Terima Pilihan 1</ListItemText>
                            </MenuItem>
                            <MenuItem dense onClick={() => updateStatus({ accept: false, selection: 1 })} disabled={items.find(item => item.id === selectedId)?.registration.accepted_1 === 'Ditolak'}>
                                <ListItemIcon>
                                    <SvgIcon>
                                        <path d={mdiClose} />
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText>Tolak Pilihan 1</ListItemText>
                            </MenuItem>
                            <MenuItem dense onClick={() => updateStatus({ accept: true, selection: 2 })} disabled={items.find(item => item.id === selectedId)?.registration.accepted_2 === 'Diterima'}>
                                <ListItemIcon>
                                    <SvgIcon>
                                        <path d={mdiCheck} />
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText>Terima Pilihan 2</ListItemText>
                            </MenuItem>
                            <MenuItem dense onClick={() => updateStatus({ accept: false, selection: 2 })} disabled={items.find(item => item.id === selectedId)?.registration.accepted_2 === 'Ditolak'}>
                                <ListItemIcon>
                                    <SvgIcon>
                                        <path d={mdiClose} />
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText>Tolak Pilihan 2</ListItemText>
                            </MenuItem>
                        </Menu>

                        <Grid container justifyContent="flex-end" direction="row" alignItems="center" style={{ marginTop: '12px' }}>
                            <Grid item xs="auto">
                                <TablePagination
                                    pagination={pagination}
                                    loading={loading}
                                    onChange={setPagination}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>}
        </Container>
    )
}

export default Account
