import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {mdiFileExport} from "@mdi/js";
import {Menu, MenuItem, SvgIcon} from "@mui/material";
import axios from "axios";
import {toast} from "react-toastify";
import {generateBasic} from "../../utils/xlsx/xlsx";
import moment from "moment";
import 'moment/locale/id'

moment.locale('id')

function exportXLSX(data) {
    generateBasic({
        title: 'PAB Restek UNY',
        subject: 'PAB Restek UNY',
        filename: 'Pembayaran.xlsx'
    }, [
        {
            name: 'Pembayaran',
            header: [
                {
                    name: 'name',
                    text: 'Nama',
                    width: 300
                },
                {
                    name: 'phone',
                    text: 'Nomor Telepon',
                    width: 100
                },
                {
                    name: 'gender',
                    text: 'Gender',
                    width: 50
                },
                {
                    name: 'student_id',
                    text: 'NIM',
                    width: 150
                },
                {
                    name: 'generation',
                    text: 'Angkatan',
                    width: 100
                },
                {
                    name: 'faculty_name',
                    text: 'Fakultas',
                    width: 200
                },
                {
                    name: 'study_program_name',
                    text: 'Program Studi',
                    width: 300
                },
                {
                    name: row => `https://pab.restek-uny.id/api/download?file=${row.file}`,
                    text: 'File',
                    width: 100
                },
                {
                    name: row => moment(row.upload_date).format('YYYY-MM-DD HH:mm'),
                    text: 'Tanggal Upload',
                    width: 100
                },
                {
                    name: (row) => {
                        let status
                        switch (row.status) {
                            case 'accepted':
                                status = 'Diterima'
                                break
                            case 'pending':
                                status = 'Menunggu'
                                break
                            case 'rejected':
                                status = 'Ditolak'
                                break
                            default:
                                status = 'Belum Membayar'
                                break
                        }
                        return status
                    },
                    text: 'Status',
                    width: 100
                },
            ],
            rows: data.map(v => ({
                name: v.user.name,
                phone: v.user.phone,
                gender: v.user.gender,
                student_id: v.user.profile.student_id,
                generation: v.user.profile.generation,
                faculty_name: v.user.profile.faculty?.name,
                study_program_name: v.user.profile.study_program?.name,
                file: v.file,
                upload_date: v.created_at,
                status: v.status,
            }))
        }
    ])
}

function ExportPaymentButton({ status, search, ...props }) {
    const [loading, setLoading] = useState(false)

    const download = async (filtered = false) => {
        handleClose()
        setLoading(true)
        try {
            const params = {
                per_page: 9999,
            }
            if (filtered) {
                params.status = status
                params.search = search
            }
            const {data} = await axios.get('/api/admin/payment/list', { params })
            exportXLSX(data.data)
        } catch (e) {
            console.log(e)
            toast('Gagal export data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return <>
        <LoadingButton
            {...props}
            loading={loading}
            aria-controls={open ? 'export-payment-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            startIcon={<SvgIcon>
                <path d={mdiFileExport}/>
            </SvgIcon>}
            onClick={handleClick}
        >
            Export
        </LoadingButton>
        <Menu
            id="export-payment-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'export-payment-menu',
            }}
        >
            <MenuItem onClick={() => download()}>Export All</MenuItem>
            <MenuItem onClick={() => download(true)}>Export Filtered</MenuItem>
        </Menu>
    </>
}

export default ExportPaymentButton
