import moment from "moment";
moment.locale('id')

function parseDMY(_date) {
    return [
        _date.year(),
        _date.format('MMMM'),
        _date.date(),
    ]
}

function range(_start, _end) {
    const start = parseDMY(moment(_start))
    const end = parseDMY(moment(_end))

    let text = []
    for (let i = 0; i < 3; i++) {
        if (start[i] === end[i]) {
            text.push(start[i])
        } else {
            const add_start = start.slice(i, 3).reverse().join(' ')
            const add_end = end.slice(i, 3).reverse().join(' ')
            text.push(add_start + ' - ' + add_end)
            break
        }
    }
    return text.reverse().join(' ')
}

export {
    range
}