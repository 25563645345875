import {Avatar, Chip, Grid, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function UserProfileCell({
                                            userId,
                                            userName,
                                            userImage,
                                            studentId,
                                            generation,
                                            studyProgramLevel,
                                            studyProgramName,
                                            facultyName,
                                            isFreeRegistration,
                                            isCashbackGiven,
                                            ...props
                                        }) {
    return (
        <Grid container wrap={false}>
            <Grid item sx={{marginRight: 2}}>
                <Tooltip title="Lihat Profil" placement="bottom">
                    <Link to={'/user?id=' + userId} target="_blank">
                        <Avatar src={userImage}/>
                    </Link>
                </Tooltip>
            </Grid>
            <Grid item>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                        }}>
                            <Typography>
                                {userName} ({studentId || '?'})
                            </Typography>
                            {Boolean(Number(isFreeRegistration)) &&
                                <Chip
                                    label="Gratis Pendaftaran"
                                    size="small"
                                    variant="contained"
                                    color="warning"
                                    sx={{marginLeft: 1}}
                                />
                            }
                            {Boolean(Number(isCashbackGiven)) &&
                                <Chip
                                    label="Cashback"
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    sx={{marginLeft: 1}}
                                />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {studyProgramLevel || ''} {studyProgramName || '?'} ({generation || '?'}) - {facultyName || '?'}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}