import * as XLSX from "xlsx";

/**
 * @param {Object} properties File properties
 * @param {string} properties.title File title
 * @param {string} properties.subject File Subject
 * @param {string} properties.filename File name
 * @param {Object[]} sheets Array of sheets data
 * @param {string} sheets.name Sheet Name
 * @param {Object[]} sheets.header Sheets header
 * @param {string} sheets.header.name Sheets header name for rows
 * @param {string} sheets.header.text Sheets header text
 * @param {string} sheets.header.width Column width in `wpx`
 * @param {Object[]} sheets.rows Data object list
 */
const generateBasic = (properties, sheets) => {
    const wb = XLSX.utils.book_new()
    wb.Props = {
        Title: properties.title,
        Subject: properties.subject,
        Author: 'Restek UNY',
        CreatedDate: new Date()
    }

    // Insert
    for (const sheet of sheets) {
        wb.SheetNames.push(sheet.name)

        let ws_data = [sheet.header.map(obj => obj.text)]

        sheet.rows.forEach(row => {
            const ws_row = []
            for (const field of sheet.header) {
                let value
                if (typeof field.name === 'function') {
                    value = field.name(row)
                } else {
                    value = row[field.name]
                }
                ws_row.push(value)
            }
            ws_data.push(ws_row)
        })

        const ws = XLSX.utils.aoa_to_sheet(ws_data)
        const range = {
            s: {r: 0, c: 0},
            e: {r: ws_data.length, c: ws_data[0].length}
        }
        ws['!ref'] = XLSX.utils.encode_range(range)
        ws['!cols'] = sheet.header.map(obj => ({wpx: obj.width}))

        wb.Sheets[sheet.name] = ws
    }

    return XLSX.writeFile(wb, properties.filename)
}

export {
    generateBasic
}