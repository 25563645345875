import {useState} from "react";
import TuneIcon from '@mui/icons-material/Tune';
import {Checkbox, FormControlLabel, IconButton, Menu, MenuItem} from "@mui/material";

export default function FilterColumn({columnList, selectedList, setSelected, ...props}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleSelect = (item) => {
        if (selectedList.includes(item)) {
            setSelected(selectedList.filter(old => old !== item));
        } else {
            setSelected(columnList.filter(old => selectedList.includes(old) || old === item));
        }
    };

    return (
        <>
            <IconButton {...props} onClick={handleClick}>
                <TuneIcon/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {columnList.map((item, index) => (
                    <MenuItem dense onClick={() => handleSelect(item)}>
                        <FormControlLabel
                            size="small"
                            control={<Checkbox
                                size="small"
                                checked={selectedList.includes(item)}
                            />}
                            label={item}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}