import {
    Card,
    CardContent,
    CardHeader,
    Container
} from "@mui/material";
import StadiumGeneraleTable from "../../../components/stadium-generale/table";

function StadiumGenerale() {
    return (
        <Container>
            <Card border="light" style={{borderRadius: '15px'}}>
                <CardHeader title="Stadium Generale"/>

                <CardContent>
                    <StadiumGeneraleTable />
                </CardContent>
            </Card>
        </Container>
    )
}

export default StadiumGenerale
