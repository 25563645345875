import {Card, CardContent, Button, Grid, Typography, Chip, Container, FormControl} from "@mui/material"
import {useState, useEffect} from "react"
import {mdiUpload, mdiTrashCan} from "@mdi/js"
import {SvgIcon} from "@mui/material";
import {Box} from "@mui/system";
import axios from "axios";
import {toast} from "react-toastify";

function Payment() {
    const [preview, setPreview] = useState()
    const [photo, setPhoto] = useState(null)
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState()
    const [already, setAlready] = useState(false)
    const [image, setImage] = useState()
    const [info, setInfo] = useState({})

    const paymentInfo = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/payment/info')
            setInfo(data)
        } catch (e) {
            toast('Gagal memuat data', {
                type: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    const fetch = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/payment/list',)
            if (data.length !== 0) {
                setStatus(data[0].status)
                setAlready(true)
                setImage(axios.defaults.baseURL + '/api/download?file=' + data[0].file)
            } else {
                setAlready(false)
            }

        } catch (e) {
            toast('Gagal memuat data', {
                type: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    const create = async () => {
        setLoading(true)
        try {
            const payload = new FormData()
            payload.append('file', photo)

            const {status, data} = await axios.post('/api/payment/create', payload)


            if (status === 201) {
                toast('Berhasil mengunggah file')
            }
        } catch (e) {
            toast('Gagal memuat file', {
                type: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!photo) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(photo)
        setPreview(objectUrl)


        return () => URL.revokeObjectURL(objectUrl)
    }, [photo])

    useEffect(() => {
        fetch()
        paymentInfo()
    }, [])

    const removeFile = () => {
        setPhoto(null)
    }

    const handleFileUpload = (e) => {
        if (!e.target.files?.length) {
            return
        }

        const file = e.target.files[0]
        setPhoto(file)
    }
    return (
        <Container>
            <Grid container sx={{justifyContent: 'center', marginTop: '20px'}}>
                <Grid item>
                    <Card sx={{minWidth: 800, maxWidth: 800, borderRadius: 3}}>
                        <CardContent>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">
                                        Pembayaran
                                    </Typography>
                                </Grid>
                                {already && <Grid item>
                                    {status === 'accepted' ?
                                        <Chip label="Diterima" color="success"/> :
                                        (status === 'rejected' ?
                                            <Chip label="Ditolak" color="error"/> :
                                            <Chip label="Menunggu" color="warning"/>)
                                    }
                                </Grid>}
                            </Grid>
                            <Grid container sx={{marginTop: '20px', justifyContent: "center"}}>
                                <Grid item xs={12}>
                                    <Typography sx={{whiteSpace: 'pre-wrap'}}>
                                        Calon anggota baru diharapkan melakukan pembayaran
                                        sebesar {new Intl.NumberFormat('id-ID', {
                                        style: 'currency',
                                        currency: 'IDR'
                                    }).format(info.payment_nominal)} ke <br/>{info.payment_account}<br/>dan setelahnya
                                        diharapkan untuk upload bukti pembayaran.
                                    </Typography>
                                </Grid>
                                <Grid item sx={{marginTop: '20px'}}>
                                    <FormControl fullwidth>
                                        {!photo ?
                                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                                {!already &&
                                                    <Button sx={{marginX: 1}}
                                                            variant="contained"
                                                            component="label"
                                                            startIcon={
                                                                <SvgIcon>
                                                                    <path d={mdiUpload}/>
                                                                </SvgIcon>
                                                            }
                                                    >
                                                        Tambah File
                                                        <input
                                                            type="file"
                                                            hidden
                                                            accept=".jpg,.png,.jpeg"
                                                            onChange={handleFileUpload}
                                                        />
                                                    </Button>}

                                            </Box> :
                                            <>
                                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={removeFile}
                                                        disabled={loading}
                                                        style={{marginRight: '12px'}}
                                                        startIcon={
                                                            <SvgIcon>
                                                                <path d={mdiTrashCan}/>
                                                            </SvgIcon>
                                                        }
                                                    >
                                                        Hapus File
                                                    </Button>

                                                    {!already &&
                                                        <Button variant="contained" sx={{
                                                            paddingX: 2,
                                                            marginX: 1
                                                        }} onClick={create}>
                                                            simpan
                                                        </Button>
                                                    }

                                                </Box>
                                                <img src={preview} style={{width: '100%', marginTop: '10px'}}/>
                                            </>}
                                        {image && <img src={image} style={{width: '100%', marginTop: '10px'}}/>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Payment;
