import {useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    SvgIcon,
    TextField
} from "@mui/material";
import {
    LoadingButton,
} from "@mui/lab"
import {mdiCheck, mdiContentSave, mdiDelete, mdiDownload, mdiTrashCan, mdiUpload} from "@mdi/js";
import Form from "./form";
import axios from "axios";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";

function AchievementForm({title, onSuccess, id, show, handleClose, handleSuccess, allowEdit}) {
    const [init, setInit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [_show, _setShow] = useState(false)
    const [form, setForm] = useState({
        competition: '',
        organizer: '',
        achievement: '',
        file: '',
        description: '',
        delete_file: false
    })
    const [preview, setPreview] = useState(null)

    const resetForm = () => {
        setForm({
            competition: '',
            organizer: '',
            achievement: '',
            file: null,
            description: '',
            delete_file: false
        })
    }

    useEffect(() => {
        if (!form.file) {
            setPreview(null)
            return
        }

        if (typeof form.file === 'string') {
            setPreview(axios.defaults.baseURL + '/api/download?file=' + form.file)
        } else {
            const objectUrl = URL.createObjectURL(form.file)
            setPreview(objectUrl)

            return () => URL.revokeObjectURL(objectUrl)
        }
    }, [form.file])

    useEffect(() => {
        resetForm()
        setInit(true)
        if (id) {
            fetch()
            _setShow(true)
        } else if (show) {
            _setShow(true)
        } else {
            _setShow(false)
        }
    }, [show, id])

    const submit = async () => {
        setInit(false)

        if (!validate()) {
            return
        }

        try {
            setLoading(true)

            const payload = new FormData()
            payload.append('competition', form.competition)
            payload.append('organizer', form.organizer)
            payload.append('achievement', form.achievement)
            payload.append('description', form.description)

            if (id) {
                payload.append('id', id)
                payload.append('file', typeof form.file !== 'string' ? form.file : null)
                payload.append('delete_file', Number(form.delete_file))
                const {status, data} = await axios.post('/api/achievement/edit', payload)

                if (status === 200) {
                    onSuccess?.()
                    toast('Berhasil mengubah data', {type: 'success'})
                }
            } else {
                payload.append('file', form.file)
                const {status, data} = await axios.post('/api/achievement', payload)

                if (status === 201) {
                    onSuccess?.(true)
                    toast('Berhasil menambahkan data', {type: 'success'})
                }
            }
        } catch (e) {
            toast(e.response?.data?.message || 'Gagal menambahkan data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    const fetch = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/achievement/detail', {
                params: {
                    id: id
                }
            })

            setForm({
                competition: data.competition,
                organizer: data.organizer,
                achievement: data.achievement,
                file: data.file,
                description: data.description,
                delete_file: false,
            })
        } catch (e) {
            toast('Gagal memuat data')
        } finally {
            setLoading(false)
        }
    }

    const handleFileUpload = (e) => {
        if (!e.target.files?.length) {
            return
        }

        const file = e.target.files[0]
        setForm(old => ({...old, file}))
    }

    const removeFile = () => {
        setForm(old => ({...old, delete_file: true, file: null}))
    }

    const validate = () => {
        return !Boolean(document.querySelectorAll('#form-achievement .Mui-error').length)
    }

    return (
        <Dialog open={show} onClose={handleClose} scroll="paper">
            <DialogTitle>
                {id ? 'Edit' : 'Tambah'} Prestasi
            </DialogTitle>
            <DialogContent id="form-achievement" style={{paddingBottom: 0}}>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                error={!init && !form.competition}
                                label="Kompetisi"
                                placeholder="Nama Kompetisi"
                                margin="normal"
                                disabled={loading}
                                value={form.competition}
                                onChange={(e) => setForm(old => ({...old, competition: e.target.value}))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                error={!init && !form.organizer}
                                label="Penyelenggara"
                                placeholder="Nama Penyelenggara"
                                margin="normal"
                                disabled={loading}
                                value={form.organizer}
                                onChange={(e) => setForm(old => ({...old, organizer: e.target.value}))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                error={!init && !form.achievement}
                                label="Pencapaian"
                                placeholder="Pencapaian"
                                margin="normal"
                                disabled={loading}
                                value={form.achievement}
                                onChange={(e) => setForm(old => ({...old, achievement: e.target.value}))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                error={!init && !form.description}
                                label="Deskripsi"
                                placeholder="Deskripsikan pencapaian anda"
                                margin="normal"
                                multiline
                                rows={4}
                                disabled={loading}
                                value={form.description}
                                onChange={(e) => setForm(old => ({...old, description: e.target.value}))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: 12}}>
                        <FormControl fullWidth>
                            <Box>
                                {!form.file ?
                                    <Button
                                        variant="contained"
                                        component="label"
                                        disabled={loading}
                                        startIcon={
                                            <SvgIcon>
                                                <path d={mdiUpload}/>
                                            </SvgIcon>
                                        }
                                    >
                                        Tambah File
                                        <input
                                            type="file"
                                            hidden
                                            accept=".pdf,.jpg,.png,.jpeg"
                                            onChange={handleFileUpload}
                                        />
                                    </Button> :
                                    <div className="d-flex">
                                        {allowEdit &&
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={removeFile}
                                                disabled={loading}
                                                style={{marginRight: '12px'}}
                                                startIcon={
                                                    <SvgIcon>
                                                        <path d={mdiTrashCan}/>
                                                    </SvgIcon>
                                                }
                                            >
                                                Hapus File
                                            </Button>}

                                        <a href={axios.defaults.baseURL + '/api/download?file=' + form.file} target="_blank" style={{textDecoration: 'none'}}>
                                            <Button
                                                variant="contained"
                                                startIcon={<SvgIcon>
                                                    <path d={mdiDownload}/>
                                                </SvgIcon>}
                                            >
                                                Unduh
                                            </Button>
                                        </a>
                                    </div>
                                }
                            </Box>
                            {preview &&
                                <iframe style={{marginTop: '12px'}} src={preview} width="100%" height="400px" frameBorder={0}/>}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item xs="auto" style={{padding: '12px'}}>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            color="secondary"
                            style={{marginRight: '12px'}}
                            disabled={loading}
                        >
                            Tutup
                        </Button>
                        {allowEdit &&
                            <LoadingButton
                                variant="contained"
                                onClick={submit}
                                loading={loading}
                                startIcon={
                                    <SvgIcon>
                                        <path d={mdiContentSave}/>
                                    </SvgIcon>
                                }
                            >
                                Simpan
                            </LoadingButton>}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default AchievementForm