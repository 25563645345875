import {useEffect, useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import Box from '@mui/material/Box'

const HEADERS = [
    'Divisi',
    'Pilihan 1',
    'Pilihan 2',
    'Jumlah',
]

function RecapitulationTable({ onData }) {
    const [loading, setLoading] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [totalSelect1, setTotalSelect1] = useState(0)
    const [totalSelect2, setTotalSelect2] = useState(0)
    const [totalAll, setTotalAll] = useState(0)

    useEffect(function () {
        fetch()
    }, [])

    const fetch = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/admin/registration/recapitulation')
            const total1 = data.reduce((a, c) => a + c.registration_select1_count, 0)
            const total2 = data.reduce((a, c) => a + c.registration_select2_count, 0)
            setTotalSelect1(total1)
            setTotalSelect2(total2)
            setTotalAll(total1 + total2)
            setDivisions(data)
            onData?.(data)
        } catch (e) {
            toast('Gagal Memuat Data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <TableContainer>
                {loading && <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>}

                <Table>
                    <TableHead>
                        <TableRow>
                            {HEADERS.map((name, i) =>
                                <TableCell key={i}>
                                    {name}
                                </TableCell>,
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {divisions.map((division, i) =>
                            <TableRow key={i}>
                                <TableCell sx={{fontWeight: 'bold'}}>{division.name}</TableCell>
                                <TableCell>{division.registration_select1_count}</TableCell>
                                <TableCell>{division.registration_select2_count}</TableCell>
                                <TableCell>{division.registration_select1_count + division.registration_select2_count}</TableCell>
                            </TableRow>,
                        )}

                        {!loading &&
                            <TableRow>
                                <TableCell sx={{fontWeight: 'bold'}}>JUMLAH</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>{totalSelect1}</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>{totalSelect2}</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>{totalAll}</TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default RecapitulationTable
