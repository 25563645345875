import {useEffect, useState} from "react";
import axios from "axios";
import {mdiClose, mdiDownload, mdiPencil, mdiPlus} from "@mdi/js";
import AchievementForm from "./form";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container, Grid, IconButton, LinearProgress, Pagination,
    Paper, Select,
    SvgIcon,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow
} from "@mui/material";
import * as PropTypes from "prop-types";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import TablePagination from "../../components/common/table/TablePagination";

const HEADERS = [
    'Kompetisi',
    'Penyelenggara',
    'Pencapaian',
    'File',
    'Aksi',
]

function Item(props) {
    return null;
}

Item.propTypes = {children: PropTypes.node};

function Achievements() {
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 5,
        last_page: 0,
        total: 0,
        prev: false,
        next: true,
        total_page: 0
    })
    const [showForm, setShowForm] = useState(false)
    const [editId, setEditId] = useState(null)
    const [allowEdit, setAllowEdit] = useState(false)

    useEffect(() => {
        checkEditAccess()
    }, [])

    const checkEditAccess = async () => {
        try {
            const {data} = await axios.get('/api/achievement/check-edit-access')
            setAllowEdit(data.allow_edit)
        } catch (e) {
        }
    }

    const setCurrentPage = (page) => {
        setLoading(true)
        setPagination((old) => ({...old, current_page: page}))
        setTimeout(() => {
            setLoading(false)
        }, 5000)
    }

    const fetch = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/achievement', {
                params: {
                    page: pagination.current_page,
                    per_page: pagination.per_page,
                }
            })

            if (data) {
                setPagination({
                    current_page: data.current_page,
                    per_page: data.per_page,
                    last_page: data.last_page,
                    prev: Boolean(data.prev),
                    next: Boolean(data.next),
                    total: data.total,
                    total_page: Math.ceil(data.total / data.per_page)
                })
                setItems(data.data)
            }
        } catch (e) {
            toast('Gagal memuat data', {
                type: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetch()
    }, [pagination.per_page, pagination.current_page])

    const onFormOpen = (id) => {
        id && setEditId(id)
        setShowForm(true)
    }

    const onFormClose = () => {
        setShowForm(false)
        setEditId(null)
    }

    const onFormSuccess = (resetPage) => {
        // resetPage && setPagination((old) => ({...old, current_page: 1}))
        fetch()
        setShowForm(false)
        setEditId(null)
    }

    return (
        <Container>
            <Card border="light" style={{borderRadius: '15px'}}>
                <CardHeader
                    title="Prestasi"
                    action={allowEdit &&
                        <Button
                            variant="contained"
                            onClick={() => onFormOpen()}
                            startIcon={<SvgIcon>
                                <path d={mdiPlus}/>
                            </SvgIcon>}
                        >
                            Tambah
                        </Button>
                    }
                />

                <AchievementForm
                    show={showForm}
                    id={editId}
                    allowEdit={allowEdit}
                    handleClose={onFormClose}
                    onSuccess={onFormSuccess}
                />

                <CardContent>
                    <TableContainer component={Paper}>
                        {loading && <Box sx={{width: '100%'}}>
                            <LinearProgress/>
                        </Box>}
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    {HEADERS.map((header) => (
                                        <TableCell>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {items.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.competition}</TableCell>
                                        <TableCell>{item.organizer}</TableCell>
                                        <TableCell>{item.achievement}</TableCell>
                                        <TableCell>
                                            {item.file &&
                                                <a href={axios.defaults.baseURL + '/api/download?file=' + item.file} target="_blank" style={{textDecoration: 'none'}}>
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<SvgIcon>
                                                            <path d={mdiDownload}/>
                                                        </SvgIcon>}
                                                    >
                                                        Unduh
                                                    </Button>
                                                </a>}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => onFormOpen(item.id)} variant="contained" color="warning">
                                                <SvgIcon>
                                                    <path d={mdiPencil}/>
                                                </SvgIcon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid container justifyContent="flex-end" direction="row" alignItems="center" style={{marginTop: '12px'}}>
                        <Grid item xs="auto">
                            {/*<Pagination className="ml-auto" count={pagination.total_page} page={pagination.current_page}/>*/}
                            <TablePagination
                                pagination={pagination}
                                loading={loading}
                                onChange={setPagination}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    )
}

export default Achievements