import {
    Avatar, Button, Chip,
    Grid, IconButton,
    LinearProgress, Menu, MenuItem,
    Paper, SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    TextField,
    Select,
    FormControl,
    InputLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {mdiCheck, mdiClose, mdiDotsVertical, mdiDownload} from "@mdi/js";
import moment from "moment/moment";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import AchievementDetail from "../../pages/admin/tasks/detail";
import TablePagination from "../common/table/TablePagination";
import ExportTaskButton from '../service/ExportTask'
import FilterColumn from "../common/table/FitlerColumn";
import * as React from "react";
import UserProfileCell from "../common/table/UserProfileCell";

function TaskTable({userId, hideUser}) {
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 5,
        last_page: 0,
        total: 0,
        prev: false,
        next: true,
        total_page: 0,
    })
    const [detailId, setDetailId] = useState(null)
    const [selectedId, setSelectedId] = useState(null)
    const [search, setSearch] = useState('')
    const [searchTimeout, setSearchTimeout] = useState(null)
    const [status, setStatus] = useState(null)

    const _headers = [
        'File Penugasan',
        'Status',
        'Waktu Unggah',
        'Aksi',
    ]

    if (!hideUser) {
        _headers.unshift('Mahasiswa')
    }

    const [headers, setHeaders] = useState(_headers)

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }
        setSearchTimeout(setTimeout(() => {
            fetch()
            setSearchTimeout(null)
        }, 1_000))
    }, [search])

    const fetch = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/admin/task/list', {
                params: {
                    page: pagination.current_page,
                    per_page: pagination.per_page,
                    search: search,
                    status: status,
                    ...(userId && {user_id: userId}),
                },
            })

            if (data) {
                setPagination({
                    current_page: data.current_page,
                    per_page: data.per_page,
                    last_page: data.last_page,
                    prev: Boolean(data.prev),
                    next: Boolean(data.next),
                    total: data.total,
                    total_page: Math.ceil(data.total / data.per_page),
                })
                setItems(data.data)
            }
        } catch (e) {
            toast('Gagal memuat data', {
                type: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetch()
    }, [pagination.per_page, pagination.current_page, status])

    /*Menu*/
    const [anchorEl, setAnchorEl] = useState(null)
    const [openMenu, setOpenMenu] = useState(false)

    const onMenuClick = (e, id) => {
        setAnchorEl(e.currentTarget)
        setOpenMenu(true)
        setSelectedId(id)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        setSelectedId(null)
        setOpenMenu(false)
    }

    const verifyTask = async (verify) => {
        const id = selectedId
        handleMenuClose()
        setLoading(true)
        try {
            const {status} = await axios.post('/api/admin/task/verify', {
                id,
                status: verify ? 'accepted' : 'rejected',
            })

            if (status === 200) {
                toast('Berhasil memperbarui data', {type: 'success'})
                fetch()
            }
        } catch (e) {
            toast('Gagal memperbarui data', {type: 'error'})
            setLoading(false)
        }
    }
    /*Menu*/

    return <>
        <AchievementDetail id={detailId} onClose={() => setDetailId(null)}/>

        <Grid container sx={{marginBottom: 2}} spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                    <TextField
                        id="search"
                        size="small"
                        label="Cari"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                    <InputLabel size="small" id="select-status">Status</InputLabel>
                    <Select
                        labelId="select-status"
                        value={status}
                        label="Status"
                        size="small"
                        onChange={e => setStatus(e.target.value)}
                        endAdornment={status &&
                            <IconButton onClick={() => setStatus(null)}>
                                <SvgIcon>
                                    <path d={mdiClose}/>
                                </SvgIcon>
                            </IconButton>}
                        sx={{
                            "& .MuiSelect-iconOutlined": {display: status ? 'none' : ''},
                        }}
                    >
                        <MenuItem dense value="accepted">Diterima</MenuItem>
                        <MenuItem dense value="pending">Menunggu</MenuItem>
                        <MenuItem dense value="rejected">Ditolak</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm="auto">
                <FilterColumn columnList={_headers} selectedList={headers} setSelected={setHeaders}/>
            </Grid>

            <Grid item sx={{ display: 'flex', marginLeft: 'auto' }}>
                <div>
                    <ExportTaskButton status={status} search={search} variant="contained" />
                </div>
            </Grid>
        </Grid>

        <TableContainer component={Paper}>
            {loading && <Box sx={{width: '100%'}}>
                <LinearProgress/>
            </Box>}
            <Table size="medium">
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell key={header}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item.id}>
                            {headers.includes('Mahasiswa') &&
                                <TableCell>
                                    <UserProfileCell
                                        userId={item.user?.id}
                                        userName={item.user?.name}
                                        userImage={item.user?.image}
                                        studentId={item.user?.profile?.student_id}
                                        generation={item.user?.profile?.generation}
                                        studyProgramLevel={item.user?.profile?.study_program?.level}
                                        studyProgramName={item.user?.profile?.study_program?.name}
                                        facultyName={item.user?.profile?.faculty?.name}
                                        isFreeRegistration={item.user?.registration?.is_free_registration}
                                        isCashbackGiven={item.user?.registration?.cashback}
                                    />
                                </TableCell>}
                            {headers.includes('File Penugasan') && <TableCell>
                                {item.file &&
                                    <a href={axios.defaults.baseURL + '/api/download?file=' + item.file} target="_blank" style={{textDecoration: 'none'}}>
                                        <Button
                                            variant="contained"
                                            startIcon={<SvgIcon>
                                                <path d={mdiDownload}/>
                                            </SvgIcon>}
                                        >
                                            Unduh
                                        </Button>
                                    </a>}
                            </TableCell>}
                            {headers.includes('Status') && <TableCell>
                                {item.status === 'accepted' ?
                                    <Chip label="Diterima" color="success"/> :
                                    (item.status === 'rejected' ?
                                        <Chip label="Ditolak" color="error"/> :
                                        <Chip label="Menunggu" color="warning"/>)
                                }
                            </TableCell>}
                            {headers.includes('Waktu Unggah') && <TableCell>
                                {moment(item.created_at).format('DD MMM YYYY HH:mm')}
                            </TableCell>}
                            {headers.includes('Aksi') && <TableCell>
                                <IconButton onClick={(e) => onMenuClick(e, item.id)} variant="contained">
                                    <SvgIcon>
                                        <path d={mdiDotsVertical}/>
                                    </SvgIcon>
                                </IconButton>
                            </TableCell>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => verifyTask(true)}>
                <ListItemIcon>
                    <SvgIcon>
                        <path d={mdiCheck}/>
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText>Verifikasi</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => verifyTask(false)}>
                <ListItemIcon>
                    <SvgIcon>
                        <path d={mdiClose}/>
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText>Tolak</ListItemText>
            </MenuItem>
        </Menu>

        <Grid container justifyContent="flex-end" direction="row" alignItems="center" style={{marginTop: '12px'}}>
            <Grid item xs="auto">
                <TablePagination
                    pagination={pagination}
                    loading={loading}
                    onChange={setPagination}
                />
            </Grid>
        </Grid>
    </>
}

export default TaskTable
