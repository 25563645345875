import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {mdiFileExport} from "@mdi/js";
import {Menu, MenuItem, SvgIcon} from "@mui/material";
import axios from "axios";
import {toast} from "react-toastify";
import {generateBasic} from "../../utils/xlsx/xlsx";
import moment from "moment";
import 'moment/locale/id'

moment.locale('id')

function exportXLSX(data) {
    generateBasic({
        title: 'PAB Restek UNY',
        subject: 'PAB Restek UNY',
        filename: 'Stadium Generale.xlsx'
    }, [
        {
            name: 'Stadium Generale',
            header: [
                {
                    name: 'name',
                    text: 'Nama',
                    width: 300
                },
                {
                    name: 'phone',
                    text: 'Nomor Telepon',
                    width: 100
                },
                {
                    name: 'gender',
                    text: 'Gender',
                    width: 50
                },
                {
                    name: 'student_id',
                    text: 'NIM',
                    width: 150
                },
                {
                    name: 'generation',
                    text: 'Angkatan',
                    width: 100
                },
                {
                    name: 'faculty_name',
                    text: 'Fakultas',
                    width: 200
                },
                {
                    name: 'study_program_name',
                    text: 'Program Studi',
                    width: 300
                },
                {
                    name: row => row.sg_attendance ? 'Hadir' : 'Tidak Hadir',
                    text: 'Kehadiran',
                    width: 100
                },
                {
                    name: 'sg_absence_reason',
                    text: 'Alasan Ketidakhadiran',
                    width: 200
                },
            ],
            rows: data.map(v => ({
                name: v.name,
                phone: v.phone,
                gender: v.gender,
                student_id: v.profile.student_id,
                generation: v.profile.generation,
                faculty_name: v.profile.faculty?.name,
                study_program_name: v.profile.study_program?.name,
                sg_attendance: v.registration.sg_attendance,
                sg_absence_reason: v.registration.sg_absence_reason,
            }))
        }
    ])
}

function ExportStadiumGeneraleButton({ attendance, search, ...props }) {
    const [loading, setLoading] = useState(false)

    const download = async (filtered = false) => {
        handleClose()
        setLoading(true)
        try {
            const params = {
                per_page: 9999,
            }
            if (filtered) {
                params.search = search
                params.sg_attendance = attendance
            }
            const {data} = await axios.get('/api/admin/registration/sg-attendance', { params })
            exportXLSX(data.data)
        } catch (e) {
            console.log(e)
            toast('Gagal export data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return <>
        <LoadingButton
            {...props}
            loading={loading}
            aria-controls={open ? 'export-stadium-generale-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            startIcon={<SvgIcon>
                <path d={mdiFileExport}/>
            </SvgIcon>}
            onClick={handleClick}
        >
            Export
        </LoadingButton>
        <Menu
            id="export-stadium-generale-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'export-stadium-generale-menu',
            }}
        >
            <MenuItem onClick={() => download()}>Export All</MenuItem>
            <MenuItem onClick={() => download(true)}>Export Filtered</MenuItem>
        </Menu>
    </>
}

export default ExportStadiumGeneraleButton
