import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {GoogleOAuthProvider} from "@react-oauth/google";
import './scss/main.scss';
// import './css/custom.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {axiosSetup} from "./service/axios";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {createTheme, ThemeProvider} from "@mui/material";
import shadows from "@mui/material/styles/shadows";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers'

axiosSetup()

const theme = createTheme({
    shadows: shadows.map(() => 'none'),
    palette: {
        primary: {
            main: '#b84dba',
            light: '#85168a',
            dark: '#ec7eed',
            contrastText: '#000',
        },

        mode: "dark",

        secondary: {
            main: '#ea8b37',
            contrastText: '#000',
        },

        background: {
            paper: '#1a1a1e',
        },
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    whiteSpace: 'normal',
                },
            },
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </BrowserRouter>
            </GoogleOAuthProvider>
        </LocalizationProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export {textToUrl} from "./utils/text/text-link";
export {addLinks2} from "./utils/text/text-link";
