import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid, IconButton, LinearProgress, Menu, MenuItem,
    SvgIcon,
    TextField
} from "@mui/material";
import {
    mdiCheck,
    mdiClose,
    mdiContentSave, mdiDotsVertical,
    mdiDownload,
    mdiFileCheck,
    mdiOpenInNew,
    mdiTrashCan,
    mdiUpload
} from "@mdi/js";
import axios from "axios";
import {LoadingButton} from "@mui/lab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {findDOMNode} from "react-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

function AchievementDetail({id, onClose, onSuccess}) {
    const [show, setShow] = useState(false)
    const [achievement, setAchievement] = useState({})
    const [loading, setLoading] = useState(false)
    const [iframeHeight, setImageHeight] = useState(0)

    const fetch = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get('/api/admin/achievement/detail', {
                params: {id: id}
            })

            setAchievement(data)
        } catch (e) {
            toast('Gagal memuat data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) {
            setShow(true)
            fetch()
        } else {
            setShow(false)
            setAchievement({})
        }
    }, [id])

    /*Menu*/
    const [anchorEl, setAnchorEl] = useState(null)
    const [openMenu, setOpenMenu] = useState(false)

    const onMenuClick = (e) => {
        setAnchorEl(e.currentTarget)
        setOpenMenu(true)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        setOpenMenu(false)
    }

    const verifyAchievement = async (accept) => {
        handleMenuClose()
        setLoading(true)
        try {
            const {data} = await axios.post('/api/admin/achievement/verify', {
                id: id,
                status: accept ? 'accepted' : 'rejected',
            })

            setAchievement(data)
            onSuccess?.()
        } catch (e) {
            toast('Gagal update data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }
    /*Menu*/

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
            >
                {achievement.status !== 'accepted' &&
                    <MenuItem onClick={() => verifyAchievement(true)}>
                        <ListItemIcon>
                            <SvgIcon>
                                <path d={mdiCheck}/>
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText>Terima</ListItemText>
                    </MenuItem>}

                {achievement.status !== 'rejected' &&
                    <MenuItem onClick={() => verifyAchievement(false)}>
                        <ListItemIcon>
                            <SvgIcon>
                                <path d={mdiClose}/>
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText>Tolak</ListItemText>
                    </MenuItem>}
            </Menu>

            <Dialog open={show} onClose={onClose} scroll="paper" fullWidth maxWidth="md">
                {loading && <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>}
                <DialogTitle>
                    <Box sx={{display: 'flex'}}>
                        <div style={{marginRight: 'auto'}}>
                            Detail Prestasi
                        </div>

                        <Box>
                            {achievement.status === 'pending' ?
                                <Chip label="Menunggu" color="warning" variant="contained"/> :
                                achievement.status === 'accepted' ?
                                    <Chip label="Diterima" color="success" variant="contained"/> :
                                    <Chip label="Ditolak" color="error" variant="contained"/>
                            }

                            <IconButton disabled={loading} onClick={(e) => onMenuClick(e)} variant="contained" sx={{marginLeft: 2}}>
                                <SvgIcon>
                                    <path d={mdiDotsVertical}/>
                                </SvgIcon>
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>

                <DialogContent style={{paddingBottom: 0}}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={4} sx={{marginBottom: 2}}>
                            <Typography sx={{fontWeight: 'bold'}}>
                                Kompetisi
                            </Typography>
                            <div>
                                {achievement.competition || '-'}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{marginBottom: 2}}>
                            <Typography sx={{fontWeight: 'bold'}}>
                                Penyelenggara
                            </Typography>
                            <div>
                                {achievement.organizer || '-'}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{marginBottom: 2}}>
                            <Typography sx={{fontWeight: 'bold'}}>
                                Pencapaian
                            </Typography>
                            <div>
                                {achievement.achievement || '-'}
                            </div>
                        </Grid>
                        <Grid item xs={12} sx={{marginBottom: 2}}>
                            <Typography sx={{fontWeight: 'bold'}}>
                                Deskripsi
                            </Typography>
                            <div>
                                {achievement.description || '-'}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex'}}>
                                <Typography sx={{fontWeight: 'bold', marginRight: 'auto'}}>
                                    Bukti Dokumen atau Gambar
                                </Typography>
                                <a href={axios.defaults.baseURL + '/api/download?file=' + achievement.file} target="_blank" style={{textDecoration: 'none'}}>
                                    <Button
                                        size="small"
                                        startIcon={<SvgIcon>
                                            <path d={mdiOpenInNew}/>
                                        </SvgIcon>}
                                    >
                                        Buka di tab baru
                                    </Button>
                                </a>
                            </Box>
                            <iframe
                                src={axios.defaults.baseURL + '/api/download?file=' + achievement.file}
                                width="100%"
                                height="400px"
                                // scrolling="no"
                                frameBorder="0"
                            ></iframe>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={onClose}>
                        Tutup
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AchievementDetail