import {LoadingButton} from "@mui/lab";
import {SvgIcon} from "@mui/material";
import {mdiCurrencyUsd} from "@mdi/js";
import {useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";

function FreeRegisterButton({ userId, onSuccess}) {
    const [loading, setLoading] = useState(false)

    const submit = async () => {
        setLoading(true)
        try {
            const {status} = await axios.post('/api/admin/registration/set-free-registration', {
                user_id: userId,
                is_free_registration: true
            })

            if (status === 200) {
                toast('Berhasil memperbarui data', {type: 'success'})
                onSuccess?.()
            }
        } catch (e) {
            toast('Gagal memperbarui data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    return (
        <LoadingButton
            loading={loading}
            variant="contained"
            color="warning"
            startIcon={<SvgIcon>
                <path d={mdiCurrencyUsd}/>
            </SvgIcon>}
            onClick={submit}
            sx={{marginLeft: 'auto'}}
        >
            Atur Gratis Pendaftaran
        </LoadingButton>
    )
}

export default FreeRegisterButton