import {
    Card,
    CardContent,
    CardHeader,
    Container
} from "@mui/material";
import AchievementsTable from "../../../components/achievements/table";

function AdminAchievements() {
    return (
        <Container>
            <Card border="light" style={{borderRadius: '15px'}}>
                <CardHeader
                    title="Prestasi"
                />

                <CardContent>
                    <AchievementsTable />
                </CardContent>
            </Card>
        </Container>
    )
}

export default AdminAchievements