import RecapitulationTable from '../../../components/registration/recapitulation'
import {Card, CardContent, CardHeader, Container} from '@mui/material'
import 'chart.js/auto'
import {Chart} from 'react-chartjs-2'
import {useEffect, useRef, useState} from 'react'

const plugin = {
    id: 'custom_canvas_background_color',
    beforeDraw: (chart) => {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
};

function Recapitulation () {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
        datasets: [],
    })

    const setData = (data) => {
        const division1 = []
        const division2 = []
        const labels = []

        data.forEach(division => {
            division1.push(division.registration_select1_count)
            division2.push(division.registration_select2_count)
            labels.push(division.name)
        })
        setChartData({
            labels,
            datasets: [
                {
                    id: 1,
                    label: 'Pilihan 1',
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.8)',
                        'rgba(255, 159, 64, 0.8)',
                        'rgba(255, 205, 86, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                        'rgba(54, 162, 235, 0.8)',
                        'rgba(153, 102, 255, 0.8)',
                        'rgba(201, 203, 207, 0.8)'
                    ],
                    data: division1
                },
                {
                    id: 2,
                    label: 'Pilihan 2',
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.8)',
                        'rgba(255, 159, 64, 0.8)',
                        'rgba(255, 205, 86, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                        'rgba(54, 162, 235, 0.8)',
                        'rgba(153, 102, 255, 0.8)',
                        'rgba(201, 203, 207, 0.8)'
                    ],
                    data: division2
                }
            ]
        })
    }

    useEffect(() => {
        const chart = chartRef.current

        if (chart) {
            setChartData({
                datasets: [{
                    // backgroundColor: createBackgroundGradient(chart.ctx),
                    backgroundColor: 'white',
                    // ...
                }]
            })
        }
    }, [])

    return <>
        <Container>
            <Card border="light" style={{ borderRadius: '15px' }} sx={{ marginBottom: 3}}>
                <CardHeader title="Rekapitulasi" />

                <CardContent>
                    <Chart
                        ref={chartRef}
                        type='bar'
                        data={chartData}
                        plugins={[]}
                    />
                </CardContent>
            </Card>
            <Card border="light" style={{ borderRadius: '15px' }}>
                <CardHeader title="Rekapitulasi" />

                <CardContent>
                    <RecapitulationTable onData={setData} />
                </CardContent>
            </Card>
        </Container>
    </>
}

export default Recapitulation
