import {
    Box,
    Button,
    Card,
    CardContent, Chip,
    Fab,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {
    mdiAccountGroup,
    mdiCheck, mdiCheckAll,
    mdiClipboardPulse,
    mdiClose,
    mdiContentSave, mdiCurrencyUsd,
    mdiOpenInNew,
    mdiPencil,
    mdiTrophy,
} from "@mdi/js";
import {green, orange, red, yellow} from "@mui/material/colors";
import {toast} from "react-toastify";
import {range} from "../../utils/date-format/date-format";
import TimelineForm from "./form";
import useAuth from "../../context/auth";
import FreeRegister from "../payment/free-register";
import {LoadingButton} from "@mui/lab";
import {addLinks2, textToUrl} from "../../utils/text/text-link";
import {useNavigate} from 'react-router-dom'

const colour = [red, yellow, green];

function Bull ({ timelineId, status, userStatus }) {
    let color
    if (Number(userStatus) > Number(timelineId)) {
        color = 'green'
    } else if (status === 'ongoing') {
        color = 'yellow'
    } else if (status === 'passed') {
        color = 'red'
    } else {
        color = 'white'
    }
    return (
        <Box
            component="span"
            sx={{
                display: 'inline-block',
                transform: 'scale(7)',
                position: 'absolute',
                marginTop: '20px',
                marginLeft: '-4px',
                color: color,
            }}
        >
            <Box sx={{
                position: 'relative',
                '&::before': {
                    position: 'absolute',
                    content: '""',
                    background: color,
                    width: '4px',
                    height: '4px',
                    borderRadius: '50%',
                    left: '-2px',
                    top: 0,
                    transform: 'translateX(.25px)',
                },
            }}>
                {color === 'green' &&
                    <SvgIcon sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: 10,
                        height: 10,
                        transform: 'translate(-40%, -50%)',
                        color: orange[500],
                    }}>
                        <path d={mdiCheck} />
                    </SvgIcon>}
            </Box>
        </Box>
    )
}

function Step ({ title, date, children, allowEdit, onEditButtonClick }) {
    return (
        <div style={{ position: 'relative' }}>
            <Card
                sx={{
                    width: 500,
                    maxWidth: 500,
                    margin: "12px 12px 12px 20px",
                    borderRadius: 3,
                }}
            >
                <CardContent sx={{ paddingBottom: "16px !important" }}>
                    <Grid container>
                        <Grid item>
                            <Typography sx={{ fontWeight: "bold" }} variant="h5" component="div">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item sx={{ marginLeft: 'auto' }}>
                            <Typography sx={{ mb: 1.5, fontWeight: "bold" }} variant="overline" color="error">
                                {date}
                            </Typography>
                        </Grid>
                    </Grid>
                    {children}
                </CardContent>
            </Card>
            {allowEdit &&
                <Tooltip title="Edit" placement="top">
                    <Fab
                        size="small"
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            transform: 'translateY(-50%)',
                        }}
                        onClick={onEditButtonClick}
                        color="secondary"
                    >
                        <SvgIcon>
                            <path d={mdiPencil} />
                        </SvgIcon>
                    </Fab>
                </Tooltip>}
        </div>
    );
}

function ActionButton ({ timeline, currentStep, adminAction, studentAction, defaultAction }) {
    const auth = useAuth()
    const isAdmin = auth.user.role === 'admin'

    if (!auth.isLoggedIn) {
        return null
    }

    if (isAdmin) {
        return adminAction
    } else if (Number(currentStep) >= Number(timeline.id) && timeline.status === 'ongoing') {
        return studentAction
    }

    return defaultAction || null
}

function SkipAchievement ({ onSuccess }) {
    const [loading, setLoading] = useState(false)

    const submit = async () => {
        setLoading(true)
        try {
            const { status } = await axios.post('/api/achievement/skip')
            if (status === 200) {
                onSuccess?.()
            }
        } catch (e) {
            toast('Gagal mengubah data', { type: 'error' })
        } finally {
            setLoading(false)
        }
    }
    return (
        <LoadingButton loading={loading} size="small" color="secondary" onClick={submit}>
            Lewati
        </LoadingButton>
    )
}

function Timeline () {
    const auth = useAuth()

    const [loading, setLoading] = useState(false)
    const [timeline, setTimeline] = useState({})
    const [editId, setEditId] = useState(null)
    const [currentStep, setCurrentStep] = useState(0)
    const [isFreeRegistration, setIsFreeRegistration] = useState(false)
    const [twibbonUrl, setTwibbonUrl] = useState('')
    const [twibbonLoading, setTwibbonLoading] = useState(false)
    const [memberId, setMemberId] = useState(null)
    const [selection, setSelection] = useState({
        division_select_1_id: null,
        division_select_2_id: null,
        accepted_1: null,
        subdivision_select_1_id: null,
        subdivision_select_2_id: null,
        accepted_2: null,
    })
    const [attendance, setAttendance] = useState(null)
    const [absenceReason, setAbsenceReason] = useState(null)
    const allowEdit = auth.user.role === 'admin'
    const navigate = useNavigate()

    const [divisions, setDivisions] = useState([])
    const fetchDivision = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get('/api/division')
            setDivisions(data)
        } catch (e) {
            toast('Gagal memuat data', { type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const fetchTimeline = async () => {
        try {
            const { data } = await axios.get('/api/timeline/list')
            const tags = {}
            data.forEach(item => {
                tags[item.tag] = item
            })
            setTimeline(tags)
        } catch (e) {
            toast('Gagal memuat timeline', { type: 'error' })
        }
    }

    const fetchRegistration = async () => {
        try {
            const { data } = await axios.get('/api/registration/me')
            setCurrentStep(data.timeline_id)
            setIsFreeRegistration(data.is_free_registration)
            setTwibbonUrl(data.twibbon_url)
            setSelection({
                division_select_1_id: data.division_select_1_id,
                division_select_2_id: data.division_select_2_id,
                accepted_1: data.accepted_1,
                subdivision_select_1_id: data.subdivision_select_1_id,
                subdivision_select_2_id: data.subdivision_select_2_id,
                accepted_2: data.accepted_2,
            })
            setAttendance(data.sg_attendance)
            setAbsenceReason(data.sg_absence_reason)

            if (data.member_number) {
                setMemberId(data.member_number.member_id)
            }

            if ([1, 2, 3].includes(data.division_select_2_id)) {
                toast('Beberapa divisi tidak dapat menjadi pilihan ke-2. Silahkan pilih kembali pilihan ke-2.', { type: 'error' })
            }
        } catch (e) {
            // toast('Gagal memuat data pendaftaran', {type: 'error'})
        }
    }

    const updateTwibbonUrl = async () => {
        setTwibbonLoading(true)
        try {
            const { status } = await axios.post('/api/twibbon/edit', {
                twibbon_url: twibbonUrl,
            })

            if (status === 200) {
                toast('Berhasil mengubah data', { type: 'success' })
                fetchRegistration()
            }
        } catch (e) {
            toast('Gagal mengubah data', { type: 'success' })
        } finally {
            setTwibbonLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            await Promise.all([
                fetchTimeline(),
                fetchRegistration(),
                fetchDivision(),
            ])
            setLoading(false)
            setTimeout(() => {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" })
            }, 1000)
        })()
    }, [])

    useEffect(() => {
        if (auth.isLoggedIn && auth.user.role === 'student' && !loading && currentStep == 1) {
            navigate('/profile?prompt=1')
        }
    }, [auth.isLoggedIn, loading])

    const onTimelineFormSuccess = () => {
        setEditId(null)
        fetchTimeline()
    }

    return (
        <>
            {allowEdit &&
                <TimelineForm
                    editId={editId}
                    onClose={() => setEditId(null)}
                    onSuccess={onTimelineFormSuccess}
                />}
            <Grid container direction="row" justifyContent="center">
                {/*<Grid item xs="auto">*/}
                {/*    <Card sx={{*/}
                {/*        width: 500,*/}
                {/*        maxWidth: 500,*/}
                {/*        margin: "12px 12px 12px 20px",*/}
                {/*        borderRadius: 3,*/}
                {/*    }}>*/}
                {/*        <CardContent sx={{ paddingBottom: "16px !important" }}>*/}
                {/*            <Grid container>*/}
                {/*                <Grid item>*/}
                {/*                    <Typography sx={{ fontWeight: "bold" }} variant="h5" component="div">*/}
                {/*                        {title}*/}
                {/*                    </Typography>*/}
                {/*                </Grid>*/}
                {/*                <Grid item sx={{ marginLeft: 'auto' }}>*/}
                {/*                    <Typography sx={{ mb: 1.5, fontWeight: "bold" }} variant="overline" color="error">*/}
                {/*                        {date}*/}
                {/*                    </Typography>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*        </CardContent>*/}
                {/*    </Card>*/}
                {/*</Grid>*/}

                <Grid item xs="auto" style={{ borderLeft: "5px dashed #48BDFF" }}>
                    {timeline.ISI_DATA_DIRI && (<>
                        <Bull
                            timelineId={timeline.ISI_DATA_DIRI.id}
                            status={timeline.ISI_DATA_DIRI.status}
                            userStatus={currentStep}
                        />
                        <Step
                            title={timeline.ISI_DATA_DIRI.title}
                            date={range(timeline.ISI_DATA_DIRI.start_date, timeline.ISI_DATA_DIRI.end_date)}
                            allowEdit={allowEdit}
                            onEditButtonClick={() => setEditId(timeline.ISI_DATA_DIRI.id)}
                        >
                            <Grid container sx={{ marginTop: "8px" }}>
                                <Grid item>
                                    <Typography variant="body2">
                                        {timeline.ISI_DATA_DIRI.description}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <ActionButton
                                timeline={timeline.ISI_DATA_DIRI}
                                currentStep={currentStep}
                                adminAction={
                                    <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                        <Grid item>
                                            <Link to="/admin/account" style={{ textDecoration: 'none' }}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<SvgIcon>
                                                        <path d={mdiAccountGroup} />
                                                    </SvgIcon>}
                                                >
                                                    Lihat Pendaftar
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                }
                                studentAction={
                                    <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                        <Grid item>
                                            <Link to="/profile" style={{ textDecoration: 'none' }}>
                                                <Button size="small" variant="contained" color="primary">
                                                    Isi Data Diri
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </Step>
                    </>)}

                    {timeline.UNGGAH_PRESTASI && (<>
                        <Bull
                            timelineId={timeline.UNGGAH_PRESTASI.id}
                            status={timeline.UNGGAH_PRESTASI.status}
                            userStatus={currentStep}
                        />
                        <Step
                            title={timeline.UNGGAH_PRESTASI.title}
                            date={range(timeline.UNGGAH_PRESTASI.start_date, timeline.UNGGAH_PRESTASI.end_date)}
                            allowEdit={allowEdit}
                            onEditButtonClick={() => setEditId(timeline.UNGGAH_PRESTASI.id)}
                        >
                            <Grid container sx={{ marginTop: "8px" }}>
                                <Grid item>
                                    <Typography variant="body2">
                                        {timeline.UNGGAH_PRESTASI.description}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <ActionButton
                                timeline={timeline.UNGGAH_PRESTASI}
                                currentStep={currentStep}
                                adminAction={
                                    <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                        <Grid item>
                                            <Link to="/admin/achievement" style={{ textDecoration: 'none' }}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<SvgIcon>
                                                        <path d={mdiTrophy} />
                                                    </SvgIcon>}
                                                >
                                                    Lihat Prestasi
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                }
                                studentAction={(
                                    <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                        <Grid item>
                                            <Link to="/achievements" style={{ textDecoration: 'none' }}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<SvgIcon>
                                                        <path d={mdiTrophy} />
                                                    </SvgIcon>}
                                                >
                                                    Unggah Prestasi
                                                </Button>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            {currentStep == timeline.UNGGAH_PRESTASI.id &&
                                                <SkipAchievement onSuccess={fetchRegistration} />}
                                        </Grid>
                                    </Grid>
                                )}
                            />
                        </Step>
                    </>)}

                    {timeline.UNGGAH_TWIBBON && (<>
                        <Bull
                            timelineId={timeline.UNGGAH_TWIBBON.id}
                            status={timeline.UNGGAH_TWIBBON.status}
                            userStatus={currentStep}
                        />
                        <Step
                            title={timeline.UNGGAH_TWIBBON.title}
                            date={range(timeline.UNGGAH_TWIBBON.start_date, timeline.UNGGAH_TWIBBON.end_date)}
                            allowEdit={allowEdit}
                            onEditButtonClick={() => setEditId(timeline.UNGGAH_TWIBBON.id)}
                        >
                            <Grid container sx={{ marginTop: "8px" }}>
                                <Grid item>
                                    <Typography variant="body2">
                                        {addLinks2(timeline.UNGGAH_TWIBBON.description)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <ActionButton
                                timeline={timeline.UNGGAH_TWIBBON}
                                currentStep={currentStep}
                                adminAction={
                                    <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                        <Grid item>
                                            <Link to="/admin/account" style={{ textDecoration: 'none' }}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<SvgIcon>
                                                        <path d={mdiAccountGroup} />
                                                    </SvgIcon>}
                                                >
                                                    Lihat Pendaftar
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                }
                                studentAction={(<>
                                    <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                        <Grid item>
                                            <a
                                                href={textToUrl(timeline.UNGGAH_TWIBBON.timeline_config?.find(v => v.name === 'TWIBBONIZE')?.value)}
                                                target="_blank"
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <Button size="small" variant="contained" color="primary">
                                                    Buat Twibbon
                                                </Button>
                                            </a>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center" direction="column" sx={{ marginTop: '20px' }}>
                                        <Grid item xs={12} sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <FormControl>
                                                <TextField
                                                    type="text"
                                                    placeholder="Link Twibbon"
                                                    value={twibbonUrl}
                                                    onChange={(e) => setTwibbonUrl(e.target.value)}
                                                />
                                            </FormControl>
                                            <IconButton
                                                href={textToUrl(twibbonUrl)}
                                                target="_blank"
                                                size="large"
                                                sx={{ marginLeft: 1 }}
                                            >
                                                <SvgIcon>
                                                    <path d={mdiOpenInNew} />
                                                </SvgIcon>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12} sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: 2,
                                        }}>
                                            <LoadingButton
                                                loading={twibbonLoading}
                                                variant="contained"
                                                startIcon={<SvgIcon>
                                                    <path d={mdiContentSave} />
                                                </SvgIcon>}
                                                onClick={updateTwibbonUrl}
                                            >
                                                Simpan
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </>)}
                                defaultAction={(currentStep >= Number(timeline.UNGGAH_TWIBBON.id)) ?
                                    <Grid container justifyContent="center" direction="column" sx={{ marginTop: '20px' }}>
                                        <Grid item xs={12} sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <FormControl>
                                                <TextField
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    type="text"
                                                    placeholder="Link Twibbon"
                                                    value={twibbonUrl}
                                                />
                                            </FormControl>
                                            <IconButton
                                                href={textToUrl(twibbonUrl)}
                                                target="_blank"
                                                size="large"
                                                sx={{ marginLeft: 1 }}
                                            >
                                                <SvgIcon>
                                                    <path d={mdiOpenInNew} />
                                                </SvgIcon>
                                            </IconButton>
                                        </Grid>
                                    </Grid> : <></>
                                }
                            />
                        </Step>
                    </>)}

                    {timeline.PEMBAYARAN && (<>
                        <Bull
                            timelineId={timeline.PEMBAYARAN.id}
                            status={timeline.PEMBAYARAN.status}
                            userStatus={currentStep}
                        />
                        <Step
                            title={timeline.PEMBAYARAN.title}
                            date={range(timeline.PEMBAYARAN.start_date, timeline.PEMBAYARAN.end_date)}
                            allowEdit={allowEdit}
                            onEditButtonClick={() => setEditId(timeline.PEMBAYARAN.id)}
                        >
                            <Grid container sx={{ marginTop: "8px" }}>
                                <Grid item>
                                    <Typography variant="body2">
                                        {timeline.PEMBAYARAN.description}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {(!loading && isFreeRegistration) ?
                                <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                    <Grid item>
                                        <FreeRegister />
                                    </Grid>
                                </Grid> :
                                <ActionButton
                                    timeline={timeline.PEMBAYARAN}
                                    currentStep={currentStep}
                                    adminAction={(
                                        <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                            <Grid item>
                                                <Link to="/admin/payment" style={{ textDecoration: 'none' }}>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<SvgIcon>
                                                            <path d={mdiCurrencyUsd} />
                                                        </SvgIcon>}
                                                    >
                                                        Lihat Pembayaran
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    )}
                                    studentAction={(
                                        <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                            <Grid item>
                                                <Link to="/payment" style={{ textDecoration: 'none' }}>
                                                    <Button size="small" variant="contained" color="primary">
                                                        Lakukan Pembayaran
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    )}
                                />
                            }
                        </Step>
                    </>)}

                    {timeline.STADIUM_GENERALE && (<>
                        <Bull
                            timelineId={timeline.STADIUM_GENERALE.id}
                            status={timeline.STADIUM_GENERALE.status}
                            userStatus={currentStep}
                        />
                        <Step
                            title={timeline.STADIUM_GENERALE.title}
                            date={range(timeline.STADIUM_GENERALE.start_date, timeline.STADIUM_GENERALE.end_date)}
                            allowEdit={allowEdit}
                            onEditButtonClick={() => setEditId(timeline.STADIUM_GENERALE.id)}
                        >
                            <Grid container sx={{ marginTop: "8px" }}>
                                <Grid item>
                                    <Typography variant="body2">
                                        {timeline.STADIUM_GENERALE.description}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <ActionButton
                                timeline={timeline.STADIUM_GENERALE}
                                currentStep={currentStep}
                                adminAction={
                                    <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                        <Grid item>
                                            <Link to="/admin/stadium-generale" style={{ textDecoration: 'none' }}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<SvgIcon>
                                                        <path d={mdiCheckAll} />
                                                    </SvgIcon>}
                                                >
                                                    Lihat Kehadiran
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                }
                                studentAction={!loading &&
                                    <AttendanceForm attendance={attendance === null ? null : Boolean(attendance)} absenceReason={absenceReason} />}
                                defaultAction={!loading &&
                                    <AttendanceForm attendance={attendance === null ? null : Boolean(attendance)} absenceReason={absenceReason} allowEdit={false} />}
                            />
                        </Step>
                    </>)}

                    {timeline.PENUGASAN_SG && (<>
                        <Bull
                            timelineId={timeline.PENUGASAN_SG.id}
                            status={timeline.PENUGASAN_SG.status}
                            userStatus={currentStep}
                        />
                        <Step
                            title={timeline.PENUGASAN_SG.title}
                            date={range(timeline.PENUGASAN_SG.start_date, timeline.PENUGASAN_SG.end_date)}
                            allowEdit={allowEdit}
                            onEditButtonClick={() => setEditId(timeline.PENUGASAN_SG.id)}
                        >
                            <Grid container sx={{ marginTop: "8px" }}>
                                <Grid item>
                                    <Typography variant="body2">
                                        {timeline.PENUGASAN_SG.description}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {!loading &&
                                <ActionButton
                                    timeline={timeline.PENUGASAN_SG}
                                    currentStep={currentStep}
                                    adminAction={(
                                        <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                            <Grid item>
                                                <Link to="/admin/task" style={{ textDecoration: 'none' }}>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        // startIcon={<SvgIcon>
                                                        //     <path d={}/>
                                                        // </SvgIcon>}
                                                    >
                                                        Lihat Penugasan
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    )}
                                    studentAction={(
                                        <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                            <Grid item>
                                                <Link to="/task" style={{ textDecoration: 'none' }}>
                                                    <Button size="small" variant="contained" color="primary">
                                                        Lihat Penugasan
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    )}
                                />
                            }
                        </Step>
                    </>)}

                    {timeline.PILIH_DIVISI && (<>
                        <Bull
                            timelineId={timeline.PILIH_DIVISI.id}
                            status={timeline.PILIH_DIVISI.status}
                            userStatus={currentStep}
                        />
                        <Step
                            title={timeline.PILIH_DIVISI.title}
                            date={range(timeline.PILIH_DIVISI.start_date, timeline.PILIH_DIVISI.end_date)}
                            allowEdit={allowEdit}
                            onEditButtonClick={() => setEditId(timeline.PILIH_DIVISI.id)}
                        >
                            <Grid container sx={{ marginTop: "8px" }}>
                                <Grid item>
                                    <Typography variant="body2">
                                        {timeline.PILIH_DIVISI.description}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <ActionButton
                                timeline={timeline.PILIH_DIVISI}
                                currentStep={currentStep}
                                adminAction={(
                                    <>
                                        <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                            <Grid item>
                                                <Link to="/admin/account" style={{ textDecoration: 'none' }}>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<SvgIcon>
                                                            <path d={mdiAccountGroup} />
                                                        </SvgIcon>}
                                                    >
                                                        Lihat Pendaftar
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                                            <Grid item>
                                                <Link to="/admin/recapitulation" style={{ textDecoration: 'none' }}>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<SvgIcon>
                                                            <path d={mdiClipboardPulse} />
                                                        </SvgIcon>}
                                                    >
                                                        Lihat Rekapitulasi
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                studentAction={!loading && <DivisionSelect selection={selection} divisions={divisions} />}
                            />
                        </Step>
                    </>)}

                    {timeline.PENGUMUMAN && (<>
                        <Bull
                            timelineId={timeline.PENGUMUMAN.id}
                            status={timeline.PENGUMUMAN.status}
                            userStatus={currentStep}
                        />
                        <Step
                            title={timeline.PENGUMUMAN.title}
                            date={range(timeline.PENGUMUMAN.start_date, timeline.PENGUMUMAN.end_date)}
                            allowEdit={allowEdit}
                            onEditButtonClick={() => setEditId(timeline.PENGUMUMAN.id)}
                        >
                            <Grid container sx={{ marginTop: "8px" }}>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {timeline.PENGUMUMAN.description}
                                    </Typography>
                                </Grid>

                                {selection.division_select_1_id && <Grid item xs={12}>
                                    <Typography variant="body">
                                        Pilihan 1: {selection.division_select_1_id ? divisions.find(division => division.id == selection.division_select_1_id)?.name : ''}
                                        <StatusChip status={selection.accepted_1} sx={{marginLeft: 1}} />
                                    </Typography>
                                </Grid>}

                                {selection.division_select_2_id && <Grid item xs={12}>
                                    <Typography variant="body">
                                        Pilihan 2: {selection.division_select_2_id ? divisions.find(division => division.id == selection.division_select_2_id)?.name : ''}
                                        <StatusChip status={selection.accepted_2} sx={{marginLeft: 1}}/>
                                    </Typography>
                                </Grid>}

                                {memberId &&
                                <Grid item xs={12}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        Nomor Induk Anggota : {memberId}
                                    </Typography>
                                </Grid>}
                            </Grid>
                        </Step>
                    </>)}
                </Grid>
            </Grid>
        </>
    )
}

function StatusChip({ status, ...props }) {
    let color
    switch (status) {
        case 'Menunggu':
            color = 'warning'
            break
        case 'Diterima':
            color = 'success'
            break
        case 'Ditolak':
            color = 'error'
            break
        default:
            break
    }

    if (color) {
        return <Chip {...props} label={status} color={color} />
    }
    return null
}

function DivisionSelect ({ selection, divisions }) {
    const [loading, setLoading] = useState(false)
    const [subdivisions1, setSubdivisions1] = useState([])
    const [subdivisions2, setSubdivisions2] = useState([])
    const [selectedDivision1Id, setSelectedDivision1Id] = useState(null)
    const [selectedSubdivision1Id, setSelectedSubdivision1Id] = useState(null)
    const [selectedDivision2Id, setSelectedDivision2Id] = useState(null)
    const [selectedSubdivision2Id, setSelectedSubdivision2Id] = useState(null)

    useEffect(() => {
        if (divisions.length) {
            initValue()
        }
    }, [divisions])

    const initValue = () => {
        selectDivision1(selection.division_select_1_id)
        setSelectedSubdivision1Id(selection.subdivision_select_1_id)
        selectDivision2(selection.division_select_2_id)
        setSelectedSubdivision2Id(selection.subdivision_select_2_id)
    }

    useEffect(() => {
        if (selectedDivision1Id === selectedDivision2Id) {
            setSelectedDivision2Id(null)
            setSubdivisions2([])
            setSelectedSubdivision2Id(null)
        }
    }, [selectedDivision1Id])

    const selectDivision1 = (value) => {
        setSelectedDivision1Id(value)

        const subdivisions = value && divisions.find(division => division.id === value)?.subdivision
        if (subdivisions?.length) {
            setSubdivisions1(subdivisions)
        } else {
            setSubdivisions1([])
            setSelectedSubdivision1Id(null)
        }
    }

    const selectDivision2 = (value) => {
        setSelectedDivision2Id(value)

        const subdivisions = value && divisions.find(division => division.id === value)?.subdivision
        if (subdivisions?.length) {
            setSubdivisions2(subdivisions)
        } else {
            setSubdivisions2([])
            setSelectedSubdivision2Id(null)
        }
    }

    const saveSelection = async () => {
        setLoading(true)
        try {
            const { status } = await axios.post('/api/registration/selection/update', {
                division_select_1_id: selectedDivision1Id,
                subdivision_select_1_id: selectedSubdivision1Id,
                division_select_2_id: selectedDivision2Id,
                subdivision_select_2_id: selectedSubdivision2Id,
            })

            if (status === 200) {
                toast('Pilihan berhasil disimpan', { type: 'success' })
            }
        } catch (e) {
            toast('Gagal menyimpan pilihan', { type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Grid container sx={{ marginTop: 2 }} spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ minWidth: 370 }}>
                        <FormControl fullWidth>
                            <InputLabel id="pilihan-1-label">Pilihan 1</InputLabel>
                            <Select
                                labelId="pilihan-1-label"
                                id="pilihan-1"
                                value={selectedDivision1Id}
                                label="Pilihan 1"
                                disabled={loading}
                                required
                                onChange={e => selectDivision1(e.target.value)}
                            >
                                {divisions.map((division) => (
                                    <MenuItem key={division.id} value={division.id}>
                                        <div style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}>
                                            {division.name}
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                {subdivisions1.length > 0 &&
                    <Grid item xs={12}>
                        <Box sx={{ minWidth: 370, paddingLeft: 5 }}>
                            <FormControl fullWidth>
                                <InputLabel id="subpilihan-1-label">
                                    Sub Pilihan 1
                                </InputLabel>
                                <Select
                                    labelId="subpilihan-1-label"
                                    id="subpilihan-1"
                                    value={selectedSubdivision1Id}
                                    label="Sub Pilihan 1"
                                    disabled={loading}
                                    required
                                    onChange={e => setSelectedSubdivision1Id(e.target.value)}
                                >
                                    {subdivisions1.map((subdivision) => (
                                        <MenuItem key={subdivision.id} value={subdivision.id}>
                                            <div style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}>
                                                {subdivision.name}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>}

                <Grid item xs={12}>
                    <Box sx={{ minWidth: 370 }}>
                        <FormControl fullWidth>
                            <InputLabel id="pilihan-2-label">Pilihan 2</InputLabel>
                            <Select
                                labelId="pilihan-2-label"
                                id="pilihan-2"
                                value={selectedDivision2Id}
                                label="Pilihan 2"
                                disabled={!Boolean(selectedDivision1Id) || loading}
                                onChange={e => selectDivision2(e.target.value)}
                                endAdornment={selectedDivision2Id &&
                                    <IconButton onClick={() => selectDivision2(null)}>
                                        <SvgIcon>
                                            <path d={mdiClose} />
                                        </SvgIcon>
                                    </IconButton>}
                                sx={{
                                    "& .MuiSelect-iconOutlined": { display: selectedDivision2Id ? 'none' : '' },
                                }}
                            >
                                {divisions.filter(division => division.allow_second_choice && division.id !== selectedDivision1Id).map((division) => (
                                    <MenuItem key={division.id} value={division.id}>
                                        <div style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}>
                                            {division.name}
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                {subdivisions2.length > 0 &&
                    <Grid item xs={12}>
                        <Box sx={{ minWidth: 370, paddingLeft: 5 }}>
                            <FormControl fullWidth>
                                <InputLabel id="subpilihan-2-label">
                                    Sub Pilihan 2
                                </InputLabel>
                                <Select
                                    labelId="subpilihan-2-label"
                                    id="subpilihan-2"
                                    value={selectedSubdivision2Id}
                                    label="Sub Pilihan 2"
                                    disabled={loading}
                                    onChange={e => setSelectedSubdivision2Id(e.target.value)}
                                >
                                    {subdivisions2.map((subdivision) => (
                                        <MenuItem key={subdivision.id} value={subdivision.id}>
                                            <div style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}>
                                                {subdivision.name}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>}
            </Grid>

            <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                <Grid item>
                    <LoadingButton
                        size="small"
                        variant="contained"
                        color="primary"
                        loading={loading}
                        disabled={(subdivisions1.length && !selectedSubdivision1Id) || (subdivisions2.length && !selectedSubdivision2Id)}
                        onClick={saveSelection}
                    >
                        Simpan Pilihan
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    )
}

function StadiumGeneraleButton ({ timeline }) {
    const [url, setUrl] = useState(null)
    useEffect(() => {
        let sg_link = timeline.timeline_config?.find(config => config.name === 'LINK_SG')?.value
        if (sg_link) {
            if (!sg_link.match(/^[a-zA-Z]+:\/\//)) {
                sg_link = 'http://' + sg_link;
            }
            setUrl(sg_link)
        }
    }, [timeline])

    return (
        <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
            <Grid item>
                <Button
                    href={url || '#'}
                    target="_blank"
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Stadium Generale
                </Button>
            </Grid>
        </Grid>
    )
}

function AttendanceForm ({ attendance, absenceReason, allowEdit = true }) {
    const [loading, setLoading] = useState(false)
    const [sgAttendance, setSgAttendance] = useState(attendance)
    const [sgAbsenceReason, setSgAbsenceReason] = useState(absenceReason)

    const submit = async () => {
        setLoading(true)
        try {
            const { status } = await axios.post('/api/registration/sg-attendance/update', {
                sg_attendance: sgAttendance,
                sg_absence_reason: sgAttendance ? null : sgAbsenceReason,
            })

            if (status === 200) {
                toast('Berhasil memperbarui data', { type: 'success' })
            }
        } catch (e) {
            toast('Gagal memperbarui data', { type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Grid container sx={{ marginTop: 2 }}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="attendance-label">Pilih Kehadiran</InputLabel>
                        <Select
                            labelId="attendance-label"
                            id="pilihan-1"
                            value={sgAttendance}
                            label="Pilih Kehadiran"
                            disabled={loading || !allowEdit}
                            required
                            onChange={e => setSgAttendance(e.target.value)}
                        >
                            <MenuItem value={true}>Hadir</MenuItem>
                            <MenuItem value={false}>Tidak Hadir</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {!sgAttendance && sgAttendance !== null &&
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                        <FormControl fullWidth>
                            <TextField
                                label="Alasan Ketidakhadiran"
                                multiline
                                style={{ marginTop: '8px' }}
                                value={sgAbsenceReason}
                                rows={4}
                                maxrows={6}
                                disabled={loading || !allowEdit}
                                onChange={e => setSgAbsenceReason(e.target.value)}
                            />
                        </FormControl>
                    </Grid>}
            </Grid>

            {allowEdit &&
                <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
                    <Grid item>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={loading}
                            disabled={sgAttendance === false && !sgAbsenceReason}
                            startIcon={<SvgIcon>
                                <path d={mdiContentSave} />
                            </SvgIcon>}
                            onClick={submit}
                        >
                            Simpan
                        </LoadingButton>
                    </Grid>
                </Grid>}
        </>
    )
}

export default Timeline
