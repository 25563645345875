import {GoogleLogin} from "@react-oauth/google";
import axios from "axios";
import React, {useEffect, useState} from "react";
import useAuth from "../../context/auth";
import MiniUserDisplay from "../navigation/MiniUserDisplay";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import {CircularProgress, TypographyVariant} from "@mui/material";
import Typography from "@mui/material/Typography";

function Login() {
    const auth = useAuth()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            setLoading(true)
            await auth.setLoggedIn()
            setLoading(false)
        })()
    }, [])

    const responseGoogle = async (response) => {
        setLoading(true)
        try {
            await axios.get('/sanctum/csrf-cookie')
            const {data} = await axios.post('/api/auth/google', {
                id_token: response.credential
            })

            localStorage.setItem('access_token', data.token)
            await auth.setLoggedIn()
            toast('Berhasil masuk', {type: 'success'})
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        } catch (e) {
            toast(e.response?.data?.error || 'Tidak dapat masuk', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {loading ?
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box>
                :
                (
                    auth.isLoggedIn ?
                        <MiniUserDisplay/> : (<>
                        <Box>
                            <Typography variant="body2" sx={{marginRight: 1}}>
                                Masuk dengan akun Student UNY
                            </Typography>
                            <GoogleLogin
                                onSuccess={responseGoogle}
                                onError={responseGoogle}
                                hosted_domain="uny.ac.id"
                                // hosted_domain={process.env.REACT_APP_GOOGLE_HOSTED_DOMAIN}
                                // useOneTap
                            />
                        </Box>
                        </>)
                )}
        </>
    )
}

export default Login