import {Card, CardContent, Grid, SvgIcon} from "@mui/material";
import {mdiInformation} from "@mdi/js";
import {blue} from "@mui/material/colors";

function FreeRegister() {
    return (
        <Card sx={{ background: blue[500], borderRadius: 3}}>
            <CardContent>
                <Grid container>
                    <Grid item>
                        <SvgIcon>
                            <path d={mdiInformation} />
                        </SvgIcon>
                    </Grid>
                    <Grid item>
                        Anda menerima bebas biaya pendaftaran. Anda akan dihubungi oleh admin untuk mendapatkan cashback biaya pendaftaran.
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default FreeRegister