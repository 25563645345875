import create from 'zustand'
import axios from 'axios'

const useAuth = create((set) => ({
    user: {},
    isLoggedIn: false,
    setLoggedIn: async (user) => {
        try {
            const {data} = await axios.get('/api/user/me')
            set({
                user: data,
                isLoggedIn: true,
                loading: false
            })
        } catch (e) {
            localStorage.removeItem('access_token')
            set({
                user: {},
                isLoggedIn: false,
                loading: false
            })
        }
    },
    setLoggedOut: () => set({
        user: {},
        isLoggedIn: false,
    }),
    loading: true
}))

export default useAuth