import axios from 'axios'

export function axiosSetup() {
    axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL
    axios.defaults.withCredentials = true
    axios.interceptors.request.use(request => {
        const token = localStorage.getItem('access_token')

        if (token) {
            request.headers.common.Authorization = `Bearer ${token}`
        }

        return request
    })

    axios.interceptors.response.use(
        (res) => {
            // Add configurations here
            // if (res.status === 201) {
            //     console.log('Posted Successfully');
            // }
            return res
        },
        (err) => {
            if (err.status === 401) {
                localStorage.setItem('access_token', null)
            }
            return Promise.reject(err)
        }
    )
}