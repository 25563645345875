import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {mdiFileExport} from "@mdi/js";
import {Menu, MenuItem, SvgIcon} from "@mui/material";
import axios from "axios";
import {toast} from "react-toastify";
import {generateBasic} from "../../utils/xlsx/xlsx";
import moment from "moment";
import 'moment/locale/id'

moment.locale('id')

function exportXLSX(data) {
    generateBasic({
        title: 'PAB Restek UNY',
        subject: 'PAB Restek UNY',
        filename: 'Pendaftar.xlsx'
    }, [
        {
            name: 'Pendaftar',
            header: [
                {
                    name: 'name',
                    text: 'Nama',
                    width: 300
                },
                {
                    name: 'phone',
                    text: 'Nomor Telepon',
                    width: 100
                },
                {
                    name: 'gender',
                    text: 'Gender',
                    width: 50
                },
                {
                    name: 'student_id',
                    text: 'NIM',
                    width: 150
                },
                {
                    name: 'member_id',
                    text: 'NIA',
                    width: 150
                },
                {
                    name: (row) => {
                        return [row.accepted_1 && row.division_select_1_name, row.accepted_2 && row.division_select_2_name].filter(Boolean).join(', ')
                    },
                    text: 'Diterima',
                    width: 150
                },
                {
                    name: 'generation',
                    text: 'Angkatan',
                    width: 100
                },
                {
                    name: 'faculty_name',
                    text: 'Fakultas',
                    width: 200
                },
                {
                    name: 'study_program_name',
                    text: 'Program Studi',
                    width: 300
                },
                {
                    name: 'birth_place',
                    text: 'Tempat Lahir',
                    width: 100
                },
                {
                    name: 'birth_date',
                    text: 'Tanggal Lahir',
                    width: 100
                },
                {
                    name: 'blood_type',
                    text: 'Gol. Darah',
                    width: 50
                },
                {
                    name: 'religion',
                    text: 'Agama',
                    width: 100
                },
                {
                    name: 'original_address',
                    text: 'Alamat Asal',
                    width: 300
                },
                {
                    name: 'current_address',
                    text: 'Alamat Saat Ini',
                    width: 300
                },
                {
                    name: 'question_restek_know',
                    text: 'Pertanyaan: Yang diketahui tentang restek',
                    width: 300
                },
                {
                    name: 'question_why_join',
                    text: 'Pertanyaan: Kenapa join restek',
                    width: 300
                },
                {
                    name: 'question_target',
                    text: 'Pertanyaan: Yang ingin dicapai',
                    width: 300
                },
                {
                    name: 'organization_experience',
                    text: 'Pertanyaan: Pengalaman organisasi',
                    width: 300
                },
                {
                    name: 'life_motto',
                    text: 'Pertanyaan: Motto hidup',
                    width: 300
                },
                {
                    name: row => `${row.achievements.length}` + (row.achievements.length ? ` (${row.achievements.filter(achievement => achievement.status === 'accepted').length} Diterima)` : ''),
                    text: 'Prestasi',
                    width: 100
                },
                {
                    name: (row) => {
                        let status
                        switch (row.payment?.status) {
                            case 'accepted':
                                status = 'Diterima'
                                break
                            case 'pending':
                                status = 'Menunggu'
                                break
                            case 'rejected':
                                status = 'Ditolak'
                                break
                            default:
                                status = 'Belum Membayar'
                                break
                        }
                        return status
                    },
                    text: 'Pembayaran',
                    width: 100
                },
                {
                    name: (row) => {
                        if (row.sg_attendance === null) {
                            return ''
                        } else {
                            return row.sg_attendance ? 'Hadir' : 'Tidak Hadir'
                        }
                    },
                    text: 'Kehadiran SG',
                    width: 100
                },
                {
                    name: 'sg_absence_reason',
                    text: 'Alasan Ketidahhadiran',
                    width: 200
                },
                {
                    name: 'division_select_1_name',
                    text: 'Pilihan 1',
                    width: 400
                },
                {
                    name: 'subdivision_select_1',
                    text: 'Pilihan 1 (Subdivisi)',
                    width: 400
                },
                {
                    name: 'division_select_1_date',
                    text: 'Pilihan 1 (Waktu Seleksi)',
                    width: 400
                },
                {
                    name: 'division_select_2_name',
                    text: 'Pilihan 2',
                    width: 400
                },
                {
                    name: 'subdivision_select_2',
                    text: 'Pilihan 2 (Subdivisi)',
                    width: 400
                },
                {
                    name: 'division_select_2_date',
                    text: 'Pilihan 2 (Waktu Seleksi)',
                    width: 400
                },
                {
                    name: row => moment(row.registration_time).format('YYYY-MM-DD HH:mm'),
                    text: 'Waktu Registrasi',
                    width: 100
                }
            ],
            rows: data.map(v => ({
                name: v.name,
                phone: v.phone,
                gender: v.gender,
                student_id: v.profile.student_id,
                generation: v.profile.generation,
                faculty_name: v.profile.faculty?.name,
                study_program_name: v.profile.study_program?.name,
                birth_place: v.profile.birth_place,
                birth_date: v.profile.birth_date,
                blood_type: v.profile.blood_type,
                religion: v.profile.religion,
                original_address: v.profile.original_address,
                current_address: v.profile.current_address,
                organization_experience: v.question.organization_experience,
                life_motto: v.question.life_motto,
                question_restek_know: v.question.question_restek_know,
                question_why_join: v.question.question_why_join,
                question_target: v.question.question_target,
                achievements: v.achievements,
                payment: v.payment,
                accepted_1: v.registration.accepted_1 === 'Diterima',
                accepted_2: v.registration.accepted_2 === 'Diterima',
                division_select_1_name: v.registration.division_select1?.name,
                subdivision_select_1: v.registration.subdivision_select_1?.map(sub => sub.name).join(', '),
                division_select_1_date: v.registration.division_select_1_date && moment(v.registration.division_select_1_date).format('LLL'),
                division_select_2_name: v.registration.division_select2?.name,
                subdivision_select_2: v.registration.subdivision_select_2?.map(sub => sub.name).join(', '),
                division_select_2_date: v.registration.division_select_2_date && moment(v.registration.division_select_2_date).format('LLL'),
                registration_time: v.created_at,
                sg_attendance: v.registration.sg_attendance,
                sg_absence_reason: v.registration.sg_absence_reason,
                member_id: v.registration.member_number?.member_id,
            }))
        }
    ])
}

function ExportRegistrationButton({ divisions, divisionId, search, ...props }) {
    const [loading, setLoading] = useState(false)

    const fetchRegistration = async (params) => {
        const {data} = await axios.get('/api/admin/registration/all', {
            params: params
        })
        return data
    }

    const getSubdivision = (divId, subdivId) => {
        const subdivisions = divisions.find(div => div.id == divId)?.subdivision
        const subdivIds = subdivId.split(',').map(Number)
        return (subdivisions || []).filter(subdivision => subdivIds.includes(subdivision.id))
    }

    const download = async (filtered = false) => {
        handleClose()
        setLoading(true)
        try {
            let params
            if (filtered) {
                params = {
                    division: divisionId,
                    search: search
                }
            }
            let {data} = await axios.get('/api/admin/registration/all', { params })
            data = data.map(row => {
                row.registration = {
                    ...row.registration,
                    subdivision_select_1: row.registration.subdivision_select_1_id && row.registration.division_select_1_id ? getSubdivision(row.registration.division_select_1_id, row.registration.subdivision_select_1_id) : [],
                    subdivision_select_2: row.registration.subdivision_select_2_id && row.registration.division_select_2_id ? getSubdivision(row.registration.division_select_2_id, row.registration.subdivision_select_2_id) : []
                }

                return row
            })
            exportXLSX(data)
        } catch (e) {
            toast('Gagal export data', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return <>
        <LoadingButton
            {...props}
            loading={loading}
            aria-controls={open ? 'export-registration-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            startIcon={<SvgIcon>
                <path d={mdiFileExport}/>
            </SvgIcon>}
            onClick={handleClick}
        >
            Export
        </LoadingButton>
        <Menu
            id="export-registration-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'export-registration-menu',
            }}
        >
            <MenuItem onClick={() => download()}>Export All</MenuItem>
            <MenuItem onClick={() => download(true)}>Export Filtered</MenuItem>
        </Menu>
    </>
}

export default ExportRegistrationButton
