import {useEffect, useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {
    Card,
    CardContent,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    CardHeader,
    Typography, Grid, Button, SvgIcon,
} from '@mui/material'
import {Box} from '@mui/system'
import {DatePicker, TimePicker, DateTimePicker} from '@mui/x-date-pickers'
import {mdiContentSave} from '@mdi/js'
import moment from 'moment'

// import {TabPanel} from '@mui/lab'

function allyProps (index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel (props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function SubdivisionCard ({
                              number,
                              division,
                              selectedSubdivisionId,
                              setSelectedSubdivisionId,
                              datetime,
                              setDatetime,
                          }) {
    const [date, setDate] = useState(datetime ? new Date(datetime) : null)

    useEffect(() => {
        setDatetime(date?.toISOString())
    }, [date])

    return (
        <Card sx={{ marginBottom: 3 }}>
            <CardContent>
                <Typography gutterBottom variant="h6" component="div" sx={{ marginBottom: 3 }}>
                    Pilihan {number}: {division.name}
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="subdivision-1-select-label">{`Subdivisi (Maksimal ${division.max_subdivision} pilihan)`}</InputLabel>
                            <Select
                                labelId="subdivision-1-select-label"
                                id="subdivision-1-select"
                                value={selectedSubdivisionId}
                                label={`Subdivisi (Maksimal ${division.max_subdivision} pilihan)`}
                                multiple
                                disabled={division.is_locked}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    if (division.max_subdivision === 1) {
                                        setSelectedSubdivisionId([e.target.value[1]])
                                    } else if (e.target.value.length > division.max_subdivision) {
                                        toast(`Maksimal ${division.max_subdivision} pilihan`, { type: 'error' })
                                    } else {
                                        setSelectedSubdivisionId(e.target.value)
                                    }
                                }}
                            >
                                {division.subdivision.map(subdivision =>
                                    <MenuItem key={subdivision.id} value={subdivision.id}>{subdivision.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    {division.date_form &&
                        <>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <DateTimePicker
                                        label="Pilih tanggal seleksi"
                                        value={date}
                                        ampm={false}
                                        inputFormat="DD-MM-YYYY HH:mm"
                                        minDate={division.min_date}
                                        maxDate={division.max_date}
                                        disabled={division.is_locked}
                                        onChange={setDate}
                                        renderInput={(params) =>
                                            <TextField {...params} />}
                                    />

                                </FormControl>
                            </Grid>
                        </>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

function SubdivisionSelect () {
    const [loading, setLoading] = useState(false)

    const [divisions, setDivisions] = useState([])
    const [division1, setDivision1] = useState(null)
    const [division2, setDivision2] = useState(null)

    const [selectedSubdivision1Id, setSelectedSubdivision1Id] = useState([])
    const [selectedSubdivision2Id, setSelectedSubdivision2Id] = useState([])
    const [date1, setDate1] = useState(null)
    const [date2, setDate2] = useState(null)

    const [tab, setTab] = useState(0)

    useEffect(() => {
        fetchDivision()
    }, [])

    useEffect(() => {
        fetchRegistration()
    }, [divisions])

    const fetchDivision = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get('/api/division')
            setDivisions(data)
        } catch (e) {
            toast('Gagal memuat data', { type: 'error' })
        } finally {
        }
    }

    const fetchRegistration = async () => {
        try {
            const { data } = await axios.get('/api/registration/me')

            setSelectedSubdivision1Id(data.subdivision_select_1_id?.split(',').filter(Boolean).map(Number) || [])
            setSelectedSubdivision2Id(data.subdivision_select_2_id?.split(',').filter(Boolean).map(Number) || [])
            setDivision1(divisions.find(division => division.id == data.division_select_1_id) || null)
            setDivision2(divisions.find(division => division.id == data.division_select_2_id) || null)
            setDate1(data.division_select_1_date)
            setDate2(data.division_select_2_date)
        } catch (e) {
            toast('Gagal memuat data pendaftaran', { type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const save = async () => {
        setLoading(true)
        try {
            const { status } = await axios.post('/api/registration/selection/subdivision/update', {
                subdivision_1_ids: selectedSubdivision1Id,
                subdivision_2_ids: selectedSubdivision2Id,
                division_1_date: date1,
                division_2_date: date2,
            })
            toast('Berhasil menyimpan data', { type: 'success' })
        } catch (e) {
            toast('Gagal menyimpan data', { type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Container>
                <Box sx={{ display: 'flex', marginBottom: 3 }}>
                    <Button
                        onClick={save}
                        variant="contained"
                        sx={{ marginLeft: 'auto' }}
                        startIcon={<SvgIcon>
                            <path d={mdiContentSave} />
                        </SvgIcon>}
                    >
                        Simpan
                    </Button>
                </Box>

                {division1 &&
                    <SubdivisionCard
                        number={1}
                        division={division1}
                        datetime={date1}
                        selectedSubdivisionId={selectedSubdivision1Id}
                        setDatetime={setDate1}
                        setSelectedSubdivisionId={setSelectedSubdivision1Id}
                    />}

                {division2 &&
                    <SubdivisionCard
                        number={2}
                        division={division2}
                        datetime={date2}
                        selectedSubdivisionId={selectedSubdivision2Id}
                        setDatetime={setDate2}
                        setSelectedSubdivisionId={setSelectedSubdivision2Id}
                    />}
            </Container>
        </>
    )
}

export default SubdivisionSelect
