import {Grid} from "@mui/material";
import Timeline from "./timeline";
import Pamflet from "./pamflet";
import {Box} from "@mui/system";

function Registration() {
    return (
        <Grid
            container
            sx={{
                flexDirection: {
                    xs: 'column-reverse',
                    md: 'row'
                }
            }}
        >
            <Grid item xs={12} md={6}>
                <Box sx={{position: 'sticky', top: '64px'}}>
                    <Timeline/>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{position: 'sticky', top: '64px'}}>
                    <Pamflet/>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Registration
