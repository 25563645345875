import useAuth from "../../context/auth";
import {
    Avatar, Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    MenuList,
    SvgIcon,
    Tooltip
} from "@mui/material";
import {useState} from "react";
import {Link} from "react-router-dom";
import {mdiAccount, mdiLogout} from "@mdi/js";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import {googleLogout} from '@react-oauth/google'
import axios from "axios";
import {toast} from "react-toastify";
import {LoadingButton} from "@mui/lab";

function MiniUserDisplay() {
    const [loading, setLoading] = useState(false)
    const auth = useAuth()
    const [anchorElUser, setAnchorElUser] = useState(null)
    const [showLogoutPrompt, setShowLogoutPrompt] = useState(false)
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }

    const logoutPrompt = () => {
        setShowLogoutPrompt(true)
        handleCloseUserMenu()
    }

    const handleCloseLogoutPrompt = () => {
        setShowLogoutPrompt(false)
    }

    const logout = async () => {
        setLoading(true)
        try {
            googleLogout()
            await axios.post('/api/logout')
            localStorage.removeItem('access_token')
            auth.setLoggedOut()
            setShowLogoutPrompt(false)
            toast('Berhasil keluar')
        } catch (e) {
            toast('Tidak dapat keluar', {type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    <Avatar src={auth.user?.image}/>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {/*{settings.map((setting) => (*/}
                {/*    <MenuItem key={setting} onClick={handleCloseUserMenu}>*/}
                {/*        <Typography textAlign="center">{setting}</Typography>*/}
                {/*    </MenuItem>*/}
                {/*))}*/}
                <MenuList style={{padding: '12px'}}>
                    {auth.user?.name}
                </MenuList>
                <Link to="/profile" style={{textDecoration: 'inherit', color: 'inherit'}}>
                    <MenuItem onClick={handleCloseUserMenu}>
                        <ListItemIcon>
                            <SvgIcon>
                                <path d={mdiAccount}/>
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText>
                            Profile
                        </ListItemText>
                    </MenuItem>
                </Link>
                <MenuItem onClick={logoutPrompt}>
                    <ListItemIcon>
                        <SvgIcon>
                            <path d={mdiLogout}/>
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText>
                        Keluar
                    </ListItemText>
                </MenuItem>
            </Menu>

            <Dialog
                open={showLogoutPrompt}
                onClose={handleCloseLogoutPrompt}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Keluar
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Anda yakin ingin keluar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} variant="contained" color="info" onClick={handleCloseLogoutPrompt}>
                        Batal
                    </Button>
                    <LoadingButton loading={loading} variant="outlined" color="error" onClick={logout}>
                        Keluar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MiniUserDisplay