import {
    Card,
    CardContent,
    CardHeader,
    Container
} from "@mui/material";
import PaymentTable from "../../../components/payments/table";

function AdminPayments() {
    return (
        <Container>
            <Card border="light" style={{borderRadius: '15px'}}>
                <CardHeader title="Pembayaran"/>

                <CardContent>
                    <PaymentTable />
                </CardContent>
            </Card>
        </Container>
    )
}

export default AdminPayments