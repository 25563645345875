const image = '/PAB22-Pemilian Divisi.jpg'
function Pamflet() {
    return (
        <a href={image} target="_blank" style={{maxWidth: '100%'}}>
            <img src={image} alt="pamflet-pab-restek-2022-sg-coming-soon" style={{ width: '100%'}} />
        </a>
    )
}

export default Pamflet
