import * as React from 'react'
import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import useAuth from '../context/auth'
import Login from './service/Login'
import {ROUTES} from '../contants/pages'
import {Link, useLocation} from 'react-router-dom'
import {useState} from 'react'
import {Drawer, Fab, Grid, SvgIcon, Tooltip, useMediaQuery} from '@mui/material'
import {mdiChat, mdiHeart, mdiRobotLove} from '@mdi/js'

const drawerWidth = 240

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const MainDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

export default function Layout({children, window}) {
    const location = useLocation()
    const theme = useTheme()
    const isSmAndUp = useMediaQuery(theme.breakpoints.up('sm'))
    const auth = useAuth()
    const [open, setOpen] = useState(false)

    const toggleDrawer = () => {
        setOpen(old => !old)
    }

    const container = window !== undefined ? () => window().document.body : undefined

    const drawer = <>
        <Toolbar/>
        <Divider/>
        <List>
            {ROUTES.filter(route => route.roles.includes(auth.user.role || 'guest') && !route.disable_menu).map((route, index) => (
                <ListItem key={index} disablePadding sx={{display: 'block'}}>
                    <Link to={route.path} style={{textDecoration: 'inherit', color: 'inherit'}}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            selected={route.path === location.pathname}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {route.icon}
                            </ListItemIcon>
                            <ListItemText primary={route.title} sx={{opacity: open ? 1 : 0}}/>
                        </ListItemButton>
                    </Link>
                </ListItem>
            ))}
        </List>
    </>

    return (
        <Box sx={{display: 'flex'}} style={{minHeight: '100vh'}}>
            <CssBaseline/>
            <AppBar position="fixed" sx={{paddingRight: '0!important'}}>
                <Toolbar className="d-flex">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{marginRight: 1}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <img src="/Logo-Atas-02-1024x221.png" style={{height: '50px', marginRight: 'auto'}}/>
                    <Login/>
                </Toolbar>
            </AppBar>

            <Box component="nav">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                {isSmAndUp ?
                    <MainDrawer
                        variant="permanent"
                        open={open}
                        anchor="left"
                    >
                        {drawer}
                    </MainDrawer> :
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={open}
                        onClose={toggleDrawer}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{'& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth}}}
                    >
                        {drawer}
                    </Drawer>
                }
            </Box>

            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <DrawerHeader/>
                {children}
                <Box sx={{height: '60px'}}/>
            </Box>

            <AppBar position="fixed" color="primary" sx={{top: 'auto', bottom: 0}}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            Made with
                            {<SvgIcon>
                                <path d={mdiHeart}/>
                            </SvgIcon>}
                            by
                            <a href="https://hello.infiniteuny.id/" target="_blank" style={{
                                textDecoration: 'none',
                                color: 'inherit'
                            }} rel="noreferrer">
                                INFINITE UNY
                            </a>
                            © 2022
                        </div>
                    </Grid>
                </Grid>
            </AppBar>
            <Tooltip title="Bantuan" placement="top">
                <Fab sx={{position: 'fixed', right: 20, bottom: 30}} href="https://wa.me/6283157112403" target="_blank">
                    <SvgIcon>
                        <path d={mdiChat}/>
                    </SvgIcon>
                </Fab>
            </Tooltip>
        </Box>
    )
}
